import {
  AdditionalFees_Fee as AdditionalFee,
  ProductCategory as ProductCategoryV2,
} from "gen/company/models_pb"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { BoolFilter } from "gen/search/service/service_pb"
import { Product_TaxStatus } from "gen/product/models_pb"
import { Vendor } from "gen/vendors/models_pb"

export interface ProductsSearchFiltersState {
  filters: {
    status: BoolFilter[]
    departments: ProductCategoryV2[]
    classes: ProductCategoryV2[]
    finelines: ProductCategoryV2[]
    vendors: Vendor[]
    additionalFees: AdditionalFee[]
    tintable: BoolFilter[]
    taxStatuses: Product_TaxStatus[]
  }
  searchQuery: string
}

const initialState: ProductsSearchFiltersState = {
  filters: {
    status: [BoolFilter.TRUE],
    departments: [],
    classes: [],
    finelines: [],
    vendors: [],
    additionalFees: [],
    tintable: [],
    taxStatuses: [],
  },
  searchQuery: "",
}

export const productsSearchSlice = createSlice({
  name: "productsSearch",
  initialState,
  reducers: {
    resetProductsSearchFilters: () => initialState,
    setProductsSearchStatusFilters: (
      state,
      action: PayloadAction<BoolFilter[]>
    ) => {
      state.filters.status = action.payload
    },
    setProductsSearchDepartmentFilters: (
      state,
      action: PayloadAction<ProductCategoryV2[]>
    ) => {
      state.filters.departments = action.payload
    },
    setProductsSearchClassFilters: (
      state,
      action: PayloadAction<ProductCategoryV2[]>
    ) => {
      state.filters.classes = action.payload
    },
    setProductsSearchFinelineFilters: (
      state,
      action: PayloadAction<ProductCategoryV2[]>
    ) => {
      state.filters.finelines = action.payload
    },
    setProductsSearchVendorFilters: (
      state,
      action: PayloadAction<Vendor[]>
    ) => {
      state.filters.vendors = action.payload
    },
    setProductsSearchAdditionalFeeFilters: (
      state,
      action: PayloadAction<AdditionalFee[]>
    ) => {
      state.filters.additionalFees = action.payload
    },
    setProductsSearchTintableFilters: (
      state,
      action: PayloadAction<BoolFilter[]>
    ) => {
      state.filters.tintable = action.payload
    },
    setProductsSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
    setProductsSearchTaxStatusFilters: (
      state,
      action: PayloadAction<Product_TaxStatus[]>
    ) => {
      state.filters.taxStatuses = action.payload
    },
  },
})

export const {
  resetProductsSearchFilters,
  setProductsSearchStatusFilters,
  setProductsSearchDepartmentFilters,
  setProductsSearchClassFilters,
  setProductsSearchFinelineFilters,
  setProductsSearchVendorFilters,
  setProductsSearchAdditionalFeeFilters,
  setProductsSearchTintableFilters,
  setProductsSearchTaxStatusFilters,
  setProductsSearchQuery,
} = productsSearchSlice.actions

export const selectProductsSearchStatusFilters = (state: AppState) =>
  state.productsSearch.filters.status
export const selectProductsSearchDepartmentFilters = (state: AppState) =>
  state.productsSearch.filters.departments
export const selectProductsSearchClassFilters = (state: AppState) =>
  state.productsSearch.filters.classes
export const selectProductsSearchFinelineFilters = (state: AppState) =>
  state.productsSearch.filters.finelines
export const selectProductsSearchVendorFilters = (state: AppState) =>
  state.productsSearch.filters.vendors
export const selectProductsSearchAdditionalFeeFilters = (state: AppState) =>
  state.productsSearch.filters.additionalFees
export const selectProductsSearchTintableFilters = (state: AppState) =>
  state.productsSearch.filters.tintable
export const selectProductsSearchQuery = (state: AppState) =>
  state.productsSearch.searchQuery
export const selectProductsSearchTaxStatusesFilters = (state: AppState) =>
  state.productsSearch.filters.taxStatuses
