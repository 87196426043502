import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { BoolFilter } from "gen/search/service/service_pb"

export interface VendorSearchFiltersState {
  filters: {
    status: BoolFilter[]
  }
  searchQuery: string
}

const initialState: VendorSearchFiltersState = {
  filters: {
    status: [BoolFilter.TRUE],
  },
  searchQuery: "",
}

export const vendorsSearchSlice = createSlice({
  name: "vendorsSearch",
  initialState,
  reducers: {
    resetVendorsSearchFilters: () => initialState,
    setVendorsSearchStatusFilters: (
      state,
      action: PayloadAction<BoolFilter[]>
    ) => {
      state.filters.status = action.payload
    },
    setVendorsSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
  },
})

export const {
  resetVendorsSearchFilters,
  setVendorsSearchStatusFilters,
  setVendorsSearchQuery,
} = vendorsSearchSlice.actions

export const selectVendorsStatusFilters = (state: AppState) =>
  state.vendorsSearch.filters.status

export const selectVendorsSearchQuery = (state: AppState) =>
  state.vendorsSearch.searchQuery
