import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"

export function product(id?: PBUUID): string {
  return `/pos/products/${UUID.idString(id)}`
}

export function products(): string {
  return "/pos/products"
}
