/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/billpayment/service.proto" (package "rd.api.billpayment", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Card } from "../../payments/models_pb";
import { PurchaseOrder } from "../../customer/models_pb";
import { JobSite } from "../../customer/models_pb";
import { Sale } from "../../txn/models_pb";
import { LedgerEntry } from "../../txn/models_pb";
import { BillPayment } from "../../txn/models_pb";
import { LedgerEntry_Refund_Method } from "../../txn/models_pb";
import { Decimal } from "../../proto/decimal/models_pb";
import { PaymentMetadata } from "../../txn/models_pb";
import { Money } from "../../proto/money/models_pb";
import { UUID } from "../../proto/uuid/models_pb";
/**
 * @generated from protobuf message rd.api.billpayment.CreateBillPaymentV3Req
 */
export interface CreateBillPaymentV3Req {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 1;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_sale_id = 3;
     */
    allocationsBySaleId: {
        [key: string]: Money;
    }; // positive
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charge_allocation = 4;
     */
    financingChargeAllocation?: Money; // positive
    /**
     * @generated from protobuf field: string notes = 5;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.txn.PaymentMetadata payment = 6;
     */
    payment?: PaymentMetadata;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 7;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal card_surcharge_rate = 8;
     */
    cardSurchargeRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal tax_rate = 9;
     */
    taxRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 10;
     */
    cardSurchargeTax?: Money;
    /**
     * @generated from protobuf field: string idempotency_key = 11;
     */
    idempotencyKey: string;
}
/**
 * @generated from protobuf message rd.api.billpayment.CreateBillPaymentV3Res
 */
export interface CreateBillPaymentV3Res {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.billpayment.RefundBillPaymentReq
 */
export interface RefundBillPaymentReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string notes = 3;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.Refund.Method refund_method = 4;
     */
    refundMethod: LedgerEntry_Refund_Method;
}
/**
 * @generated from protobuf message rd.api.billpayment.RefundBillPaymentRes
 */
export interface RefundBillPaymentRes {
}
/**
 * @generated from protobuf message rd.api.billpayment.CreateWriteOffReq
 */
export interface CreateWriteOffReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 1;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_sale_id = 3;
     */
    allocationsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charge_allocation = 4;
     */
    financingChargeAllocation?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 5;
     */
    amount?: Money;
    /**
     * @generated from protobuf field: string notes = 6;
     */
    notes: string;
}
/**
 * @generated from protobuf message rd.api.billpayment.CreateWriteOffRes
 */
export interface CreateWriteOffRes {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetBillPaymentReq
 */
export interface GetBillPaymentReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetBillPaymentRes
 */
export interface GetBillPaymentRes {
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 1;
     */
    billPayment?: BillPayment;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry payment_ledger_entry = 2;
     */
    paymentLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry write_off_ledger_entry = 3;
     */
    writeOffLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 4;
     */
    amount?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charge_allocation = 5;
     */
    financingChargeAllocation?: Money;
    /**
     * @generated from protobuf field: map<string, rd.txn.Sale> sales_by_id = 6;
     */
    salesById: {
        [key: string]: Sale;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_sale_id = 7;
     */
    allocationsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.customer.JobSite> job_sites_by_id = 8;
     */
    jobSitesById: {
        [key: string]: JobSite;
    };
    /**
     * @generated from protobuf field: map<string, rd.customer.PurchaseOrder> purchase_orders_by_id = 9;
     */
    purchaseOrdersById: {
        [key: string]: PurchaseOrder;
    };
    /**
     * @generated from protobuf field: rd.payments.Card payment_card = 10;
     */
    paymentCard?: Card;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 11;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 12;
     */
    cardSurchargeTax?: Money;
    /**
     * @generated from protobuf field: string payment_method = 13;
     */
    paymentMethod: string;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetScannedBillPaymentReq
 */
export interface GetScannedBillPaymentReq {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetScannedBillPaymentRes
 */
export interface GetScannedBillPaymentRes {
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 1;
     */
    billPayment?: BillPayment;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetBillPaymentPDFReq
 */
export interface GetBillPaymentPDFReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetBillPaymentPDFRes
 */
export interface GetBillPaymentPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.billpayment.EmailBillPaymentPDFReq
 */
export interface EmailBillPaymentPDFReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: repeated string customer_contact_id_strings = 2;
     */
    customerContactIdStrings: string[];
    /**
     * @generated from protobuf field: repeated string additional_emails = 3;
     */
    additionalEmails: string[];
}
/**
 * @generated from protobuf message rd.api.billpayment.EmailBillPaymentPDFRes
 */
export interface EmailBillPaymentPDFRes {
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateBillPaymentV3Req$Type extends MessageType<CreateBillPaymentV3Req> {
    constructor() {
        super("rd.api.billpayment.CreateBillPaymentV3Req", [
            { no: 1, name: "customer_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 3, name: "allocations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 4, name: "financing_charge_allocation", kind: "message", T: () => Money },
            { no: 5, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "payment", kind: "message", T: () => PaymentMetadata },
            { no: 7, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 8, name: "card_surcharge_rate", kind: "message", T: () => Decimal },
            { no: 9, name: "tax_rate", kind: "message", T: () => Decimal },
            { no: 10, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 11, name: "idempotency_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateBillPaymentV3Req>): CreateBillPaymentV3Req {
        const message = { allocationsBySaleId: {}, notes: "", idempotencyKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateBillPaymentV3Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateBillPaymentV3Req): CreateBillPaymentV3Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID customer_id */ 1:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_sale_id */ 3:
                    this.binaryReadMap3(message.allocationsBySaleId, reader, options);
                    break;
                case /* rd.proto.money.Money financing_charge_allocation */ 4:
                    message.financingChargeAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingChargeAllocation);
                    break;
                case /* string notes */ 5:
                    message.notes = reader.string();
                    break;
                case /* rd.txn.PaymentMetadata payment */ 6:
                    message.payment = PaymentMetadata.internalBinaryRead(reader, reader.uint32(), options, message.payment);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 7:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.decimal.Decimal card_surcharge_rate */ 8:
                    message.cardSurchargeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeRate);
                    break;
                case /* rd.proto.decimal.Decimal tax_rate */ 9:
                    message.taxRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 10:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* string idempotency_key */ 11:
                    message.idempotencyKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: CreateBillPaymentV3Req["allocationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateBillPaymentV3Req["allocationsBySaleId"] | undefined, val: CreateBillPaymentV3Req["allocationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.CreateBillPaymentV3Req.allocations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: CreateBillPaymentV3Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID customer_id = 1; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> allocations_by_sale_id = 3; */
        for (let k of Object.keys(message.allocationsBySaleId)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money financing_charge_allocation = 4; */
        if (message.financingChargeAllocation)
            Money.internalBinaryWrite(message.financingChargeAllocation, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 5; */
        if (message.notes !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.notes);
        /* rd.txn.PaymentMetadata payment = 6; */
        if (message.payment)
            PaymentMetadata.internalBinaryWrite(message.payment, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 7; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal card_surcharge_rate = 8; */
        if (message.cardSurchargeRate)
            Decimal.internalBinaryWrite(message.cardSurchargeRate, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal tax_rate = 9; */
        if (message.taxRate)
            Decimal.internalBinaryWrite(message.taxRate, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 10; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string idempotency_key = 11; */
        if (message.idempotencyKey !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.idempotencyKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.CreateBillPaymentV3Req
 */
export const CreateBillPaymentV3Req = new CreateBillPaymentV3Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateBillPaymentV3Res$Type extends MessageType<CreateBillPaymentV3Res> {
    constructor() {
        super("rd.api.billpayment.CreateBillPaymentV3Res", [
            { no: 1, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<CreateBillPaymentV3Res>): CreateBillPaymentV3Res {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateBillPaymentV3Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateBillPaymentV3Res): CreateBillPaymentV3Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateBillPaymentV3Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.CreateBillPaymentV3Res
 */
export const CreateBillPaymentV3Res = new CreateBillPaymentV3Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefundBillPaymentReq$Type extends MessageType<RefundBillPaymentReq> {
    constructor() {
        super("rd.api.billpayment.RefundBillPaymentReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 3, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 4, name: "refund_method", kind: "enum", T: () => ["rd.txn.LedgerEntry.Refund.Method", LedgerEntry_Refund_Method, "METHOD_"], options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<RefundBillPaymentReq>): RefundBillPaymentReq {
        const message = { notes: "", refundMethod: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RefundBillPaymentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefundBillPaymentReq): RefundBillPaymentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string notes */ 3:
                    message.notes = reader.string();
                    break;
                case /* rd.txn.LedgerEntry.Refund.Method refund_method */ 4:
                    message.refundMethod = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefundBillPaymentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 3; */
        if (message.notes !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.notes);
        /* rd.txn.LedgerEntry.Refund.Method refund_method = 4; */
        if (message.refundMethod !== 0)
            writer.tag(4, WireType.Varint).int32(message.refundMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.RefundBillPaymentReq
 */
export const RefundBillPaymentReq = new RefundBillPaymentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefundBillPaymentRes$Type extends MessageType<RefundBillPaymentRes> {
    constructor() {
        super("rd.api.billpayment.RefundBillPaymentRes", []);
    }
    create(value?: PartialMessage<RefundBillPaymentRes>): RefundBillPaymentRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RefundBillPaymentRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefundBillPaymentRes): RefundBillPaymentRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RefundBillPaymentRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.RefundBillPaymentRes
 */
export const RefundBillPaymentRes = new RefundBillPaymentRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateWriteOffReq$Type extends MessageType<CreateWriteOffReq> {
    constructor() {
        super("rd.api.billpayment.CreateWriteOffReq", [
            { no: 1, name: "customer_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 3, name: "allocations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 4, name: "financing_charge_allocation", kind: "message", T: () => Money },
            { no: 5, name: "amount", kind: "message", T: () => Money, options: { "rd.validator.require": true } },
            { no: 6, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<CreateWriteOffReq>): CreateWriteOffReq {
        const message = { allocationsBySaleId: {}, notes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateWriteOffReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateWriteOffReq): CreateWriteOffReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID customer_id */ 1:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_sale_id */ 3:
                    this.binaryReadMap3(message.allocationsBySaleId, reader, options);
                    break;
                case /* rd.proto.money.Money financing_charge_allocation */ 4:
                    message.financingChargeAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingChargeAllocation);
                    break;
                case /* rd.proto.money.Money amount */ 5:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* string notes */ 6:
                    message.notes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: CreateWriteOffReq["allocationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateWriteOffReq["allocationsBySaleId"] | undefined, val: CreateWriteOffReq["allocationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.CreateWriteOffReq.allocations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: CreateWriteOffReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID customer_id = 1; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> allocations_by_sale_id = 3; */
        for (let k of Object.keys(message.allocationsBySaleId)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money financing_charge_allocation = 4; */
        if (message.financingChargeAllocation)
            Money.internalBinaryWrite(message.financingChargeAllocation, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money amount = 5; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 6; */
        if (message.notes !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.notes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.CreateWriteOffReq
 */
export const CreateWriteOffReq = new CreateWriteOffReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateWriteOffRes$Type extends MessageType<CreateWriteOffRes> {
    constructor() {
        super("rd.api.billpayment.CreateWriteOffRes", [
            { no: 1, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<CreateWriteOffRes>): CreateWriteOffRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateWriteOffRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateWriteOffRes): CreateWriteOffRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateWriteOffRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.CreateWriteOffRes
 */
export const CreateWriteOffRes = new CreateWriteOffRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillPaymentReq$Type extends MessageType<GetBillPaymentReq> {
    constructor() {
        super("rd.api.billpayment.GetBillPaymentReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetBillPaymentReq>): GetBillPaymentReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillPaymentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillPaymentReq): GetBillPaymentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBillPaymentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetBillPaymentReq
 */
export const GetBillPaymentReq = new GetBillPaymentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillPaymentRes$Type extends MessageType<GetBillPaymentRes> {
    constructor() {
        super("rd.api.billpayment.GetBillPaymentRes", [
            { no: 1, name: "bill_payment", kind: "message", T: () => BillPayment },
            { no: 2, name: "payment_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 3, name: "write_off_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 4, name: "amount", kind: "message", T: () => Money },
            { no: 5, name: "financing_charge_allocation", kind: "message", T: () => Money },
            { no: 6, name: "sales_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Sale } },
            { no: 7, name: "allocations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 8, name: "job_sites_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => JobSite } },
            { no: 9, name: "purchase_orders_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => PurchaseOrder } },
            { no: 10, name: "payment_card", kind: "message", T: () => Card },
            { no: 11, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 12, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 13, name: "payment_method", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBillPaymentRes>): GetBillPaymentRes {
        const message = { salesById: {}, allocationsBySaleId: {}, jobSitesById: {}, purchaseOrdersById: {}, paymentMethod: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillPaymentRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillPaymentRes): GetBillPaymentRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.BillPayment bill_payment */ 1:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.txn.LedgerEntry payment_ledger_entry */ 2:
                    message.paymentLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntry);
                    break;
                case /* rd.txn.LedgerEntry write_off_ledger_entry */ 3:
                    message.writeOffLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.writeOffLedgerEntry);
                    break;
                case /* rd.proto.money.Money amount */ 4:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* rd.proto.money.Money financing_charge_allocation */ 5:
                    message.financingChargeAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingChargeAllocation);
                    break;
                case /* map<string, rd.txn.Sale> sales_by_id */ 6:
                    this.binaryReadMap6(message.salesById, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_sale_id */ 7:
                    this.binaryReadMap7(message.allocationsBySaleId, reader, options);
                    break;
                case /* map<string, rd.customer.JobSite> job_sites_by_id */ 8:
                    this.binaryReadMap8(message.jobSitesById, reader, options);
                    break;
                case /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_id */ 9:
                    this.binaryReadMap9(message.purchaseOrdersById, reader, options);
                    break;
                case /* rd.payments.Card payment_card */ 10:
                    message.paymentCard = Card.internalBinaryRead(reader, reader.uint32(), options, message.paymentCard);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 11:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 12:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* string payment_method */ 13:
                    message.paymentMethod = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: GetBillPaymentRes["salesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetBillPaymentRes["salesById"] | undefined, val: GetBillPaymentRes["salesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Sale.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.GetBillPaymentRes.sales_by_id");
            }
        }
        map[key ?? ""] = val ?? Sale.create();
    }
    private binaryReadMap7(map: GetBillPaymentRes["allocationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetBillPaymentRes["allocationsBySaleId"] | undefined, val: GetBillPaymentRes["allocationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.GetBillPaymentRes.allocations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap8(map: GetBillPaymentRes["jobSitesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetBillPaymentRes["jobSitesById"] | undefined, val: GetBillPaymentRes["jobSitesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = JobSite.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.GetBillPaymentRes.job_sites_by_id");
            }
        }
        map[key ?? ""] = val ?? JobSite.create();
    }
    private binaryReadMap9(map: GetBillPaymentRes["purchaseOrdersById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetBillPaymentRes["purchaseOrdersById"] | undefined, val: GetBillPaymentRes["purchaseOrdersById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.GetBillPaymentRes.purchase_orders_by_id");
            }
        }
        map[key ?? ""] = val ?? PurchaseOrder.create();
    }
    internalBinaryWrite(message: GetBillPaymentRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.BillPayment bill_payment = 1; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry payment_ledger_entry = 2; */
        if (message.paymentLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.paymentLedgerEntry, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry write_off_ledger_entry = 3; */
        if (message.writeOffLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.writeOffLedgerEntry, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money amount = 4; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money financing_charge_allocation = 5; */
        if (message.financingChargeAllocation)
            Money.internalBinaryWrite(message.financingChargeAllocation, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.txn.Sale> sales_by_id = 6; */
        for (let k of Object.keys(message.salesById)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Sale.internalBinaryWrite(message.salesById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> allocations_by_sale_id = 7; */
        for (let k of Object.keys(message.allocationsBySaleId)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.customer.JobSite> job_sites_by_id = 8; */
        for (let k of Object.keys(message.jobSitesById)) {
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            JobSite.internalBinaryWrite(message.jobSitesById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_id = 9; */
        for (let k of Object.keys(message.purchaseOrdersById)) {
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            PurchaseOrder.internalBinaryWrite(message.purchaseOrdersById[k], writer, options);
            writer.join().join();
        }
        /* rd.payments.Card payment_card = 10; */
        if (message.paymentCard)
            Card.internalBinaryWrite(message.paymentCard, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 11; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 12; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string payment_method = 13; */
        if (message.paymentMethod !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.paymentMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetBillPaymentRes
 */
export const GetBillPaymentRes = new GetBillPaymentRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetScannedBillPaymentReq$Type extends MessageType<GetScannedBillPaymentReq> {
    constructor() {
        super("rd.api.billpayment.GetScannedBillPaymentReq", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetScannedBillPaymentReq>): GetScannedBillPaymentReq {
        const message = { identifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetScannedBillPaymentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetScannedBillPaymentReq): GetScannedBillPaymentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetScannedBillPaymentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetScannedBillPaymentReq
 */
export const GetScannedBillPaymentReq = new GetScannedBillPaymentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetScannedBillPaymentRes$Type extends MessageType<GetScannedBillPaymentRes> {
    constructor() {
        super("rd.api.billpayment.GetScannedBillPaymentRes", [
            { no: 1, name: "bill_payment", kind: "message", T: () => BillPayment }
        ]);
    }
    create(value?: PartialMessage<GetScannedBillPaymentRes>): GetScannedBillPaymentRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetScannedBillPaymentRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetScannedBillPaymentRes): GetScannedBillPaymentRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.BillPayment bill_payment */ 1:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetScannedBillPaymentRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.BillPayment bill_payment = 1; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetScannedBillPaymentRes
 */
export const GetScannedBillPaymentRes = new GetScannedBillPaymentRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillPaymentPDFReq$Type extends MessageType<GetBillPaymentPDFReq> {
    constructor() {
        super("rd.api.billpayment.GetBillPaymentPDFReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetBillPaymentPDFReq>): GetBillPaymentPDFReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillPaymentPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillPaymentPDFReq): GetBillPaymentPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBillPaymentPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetBillPaymentPDFReq
 */
export const GetBillPaymentPDFReq = new GetBillPaymentPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillPaymentPDFRes$Type extends MessageType<GetBillPaymentPDFRes> {
    constructor() {
        super("rd.api.billpayment.GetBillPaymentPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetBillPaymentPDFRes>): GetBillPaymentPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillPaymentPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillPaymentPDFRes): GetBillPaymentPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBillPaymentPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetBillPaymentPDFRes
 */
export const GetBillPaymentPDFRes = new GetBillPaymentPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailBillPaymentPDFReq$Type extends MessageType<EmailBillPaymentPDFReq> {
    constructor() {
        super("rd.api.billpayment.EmailBillPaymentPDFReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "customer_contact_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "additional_emails", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EmailBillPaymentPDFReq>): EmailBillPaymentPDFReq {
        const message = { customerContactIdStrings: [], additionalEmails: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmailBillPaymentPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmailBillPaymentPDFReq): EmailBillPaymentPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* repeated string customer_contact_id_strings */ 2:
                    message.customerContactIdStrings.push(reader.string());
                    break;
                case /* repeated string additional_emails */ 3:
                    message.additionalEmails.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EmailBillPaymentPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string customer_contact_id_strings = 2; */
        for (let i = 0; i < message.customerContactIdStrings.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.customerContactIdStrings[i]);
        /* repeated string additional_emails = 3; */
        for (let i = 0; i < message.additionalEmails.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.additionalEmails[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.EmailBillPaymentPDFReq
 */
export const EmailBillPaymentPDFReq = new EmailBillPaymentPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailBillPaymentPDFRes$Type extends MessageType<EmailBillPaymentPDFRes> {
    constructor() {
        super("rd.api.billpayment.EmailBillPaymentPDFRes", []);
    }
    create(value?: PartialMessage<EmailBillPaymentPDFRes>): EmailBillPaymentPDFRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmailBillPaymentPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmailBillPaymentPDFRes): EmailBillPaymentPDFRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EmailBillPaymentPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.EmailBillPaymentPDFRes
 */
export const EmailBillPaymentPDFRes = new EmailBillPaymentPDFRes$Type();
/**
 * @generated ServiceType for protobuf service rd.api.billpayment.BillPaymentService
 */
export const BillPaymentService = new ServiceType("rd.api.billpayment.BillPaymentService", [
    { name: "CreateBillPaymentV3", options: {}, I: CreateBillPaymentV3Req, O: CreateBillPaymentV3Res },
    { name: "RefundBillPayment", options: {}, I: RefundBillPaymentReq, O: RefundBillPaymentRes },
    { name: "CreateWriteOff", options: {}, I: CreateWriteOffReq, O: CreateWriteOffRes },
    { name: "GetBillPayment", options: {}, I: GetBillPaymentReq, O: GetBillPaymentRes },
    { name: "GetScannedBillPayment", options: {}, I: GetScannedBillPaymentReq, O: GetScannedBillPaymentRes },
    { name: "GetBillPaymentPDF", options: {}, I: GetBillPaymentPDFReq, O: GetBillPaymentPDFRes },
    { name: "EmailBillPaymentPDF", options: {}, I: EmailBillPaymentPDFReq, O: EmailBillPaymentPDFRes }
]);
