import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"
import { stringify } from "lib/stringify"

export const checkProductsEquality = (
  productsA: { id?: PBUUID }[],
  productsB: { id?: PBUUID }[]
) => {
  if (!productsA && !productsB) {
    return true
  }
  if (!productsA || !productsB) {
    return false
  }
  if (productsA.length !== productsB.length) {
    return false
  }
  productsA.sort((pa, pb) => UUID.comparePB(pa.id, pb.id))
  productsB.sort((pa, pb) => UUID.comparePB(pa.id, pb.id))
  return stringify(productsA) === stringify(productsB)
}
