/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "price/models.proto" (package "rd.price", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { Decimal } from "../proto/decimal/models_pb";
import { Money } from "../proto/money/models_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * @generated from protobuf message rd.price.Rule
 */
export interface Rule {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 2;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 3;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 4;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID matched_product_id = 5;
     */
    matchedProductId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 6;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID department_id = 7;
     */
    departmentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID class_id = 8;
     */
    classId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID fineline_id = 9;
     */
    finelineId?: UUID;
    /**
     * @generated from protobuf field: string pricing_tier_id_string = 10;
     */
    pricingTierIdString: string;
    /**
     * @generated from protobuf field: string reference_tier_id_string = 11;
     */
    referenceTierIdString: string; // for discount price strategy
    /**
     * @generated from protobuf field: rd.price.Rule.PriceStrategy price_strategy = 13;
     */
    priceStrategy: Rule_PriceStrategy;
    /**
     * Value
     *
     * @generated from protobuf field: rd.proto.money.Money fixed_price = 14;
     */
    fixedPrice?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal margin_percent = 15;
     */
    marginPercent?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal discount_percent = 16;
     */
    discountPercent?: Decimal;
    /**
     * For custom prices
     *
     * @generated from protobuf field: google.protobuf.Timestamp expires_at = 17;
     */
    expiresAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.price.Rule.Type type = 18;
     */
    type: Rule_Type;
    /**
     * @generated from protobuf field: bool is_active = 19;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 20;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 21;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: bool is_price_rounded_up = 22;
     */
    isPriceRoundedUp: boolean;
    /**
     * @generated from protobuf field: string name = 23;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.price.Rule.PriceTierType price_tier_type = 24;
     */
    priceTierType: Rule_PriceTierType;
}
/**
 * @generated from protobuf enum rd.price.Rule.PriceStrategy
 */
export enum Rule_PriceStrategy {
    /**
     * @generated from protobuf enum value: PRICE_STRATEGY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: PRICE_STRATEGY_FIXED = 1;
     */
    FIXED = 1,
    /**
     * @generated from protobuf enum value: PRICE_STRATEGY_MARGIN = 2;
     */
    MARGIN = 2,
    /**
     * @generated from protobuf enum value: PRICE_STRATEGY_DISCOUNT = 3;
     */
    DISCOUNT = 3
}
/**
 * Type is used to distinguish between custom prices and pricing tier prices
 *
 * @generated from protobuf enum rd.price.Rule.Type
 */
export enum Rule_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_PRICE_TIER = 1;
     */
    PRICE_TIER = 1,
    /**
     * @generated from protobuf enum value: TYPE_CUSTOM_PRICE = 2;
     */
    CUSTOM_PRICE = 2,
    /**
     * @generated from protobuf enum value: TYPE_MATCH_PRICE = 3;
     */
    MATCH_PRICE = 3
}
/**
 * PriceTierType is used to distinguish between manual price tier rules and
 * rule-based price tier rules
 *
 * @generated from protobuf enum rd.price.Rule.PriceTierType
 */
export enum Rule_PriceTierType {
    /**
     * @generated from protobuf enum value: PRICE_TIER_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: PRICE_TIER_TYPE_MANUAL = 1;
     */
    MANUAL = 1,
    /**
     * @generated from protobuf enum value: PRICE_TIER_TYPE_RULE_BASED = 2;
     */
    RULE_BASED = 2
}
/**
 * @generated from protobuf message rd.price.Tier
 */
export interface Tier {
    /**
     * @generated from protobuf field: rd.proto.money.Money price = 1;
     */
    price?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal margin_percent = 2;
     */
    marginPercent?: Decimal;
    /**
     * @generated from protobuf field: rd.price.Rule.PriceStrategy price_strategy = 3;
     */
    priceStrategy: Rule_PriceStrategy;
    /**
     * @generated from protobuf field: string tier_id_string = 4;
     */
    tierIdString: string;
    /**
     * @generated from protobuf field: bool is_price_rounded_up = 6;
     */
    isPriceRoundedUp: boolean;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal discount_percent = 7;
     */
    discountPercent?: Decimal;
    /**
     * @generated from protobuf field: bool is_reference = 8;
     */
    isReference: boolean;
    /**
     * @generated from protobuf field: string name = 9;
     */
    name: string;
    /**
     * @generated from protobuf field: bool is_rule_based = 10;
     */
    isRuleBased: boolean;
}
/**
 * @generated from protobuf message rd.price.TierRule
 */
export interface TierRule {
    /**
     * @generated from protobuf field: string pricing_tier_id_string = 1;
     */
    pricingTierIdString: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 2;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID department_id = 3;
     */
    departmentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID class_id = 4;
     */
    classId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID fineline_id = 5;
     */
    finelineId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 6;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: rd.price.Rule.PriceStrategy price_strategy = 12;
     */
    priceStrategy: Rule_PriceStrategy;
    /**
     * @generated from protobuf field: rd.proto.money.Money price = 13;
     */
    price?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal margin_percent = 14;
     */
    marginPercent?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal discount_percent = 15;
     */
    discountPercent?: Decimal;
    /**
     * @generated from protobuf field: string reference_tier_id_string = 16;
     */
    referenceTierIdString: string;
    /**
     * @generated from protobuf field: bool is_price_rounded_up = 17;
     */
    isPriceRoundedUp: boolean;
    /**
     * @generated from protobuf field: string name = 18;
     */
    name: string;
    /**
     * @generated from protobuf field: string original_string_id = 19;
     */
    originalStringId: string;
}
/**
 * @generated from protobuf message rd.price.Custom
 */
export interface Custom {
    /**
     * @generated from protobuf field: rd.proto.money.Money price = 1;
     */
    price?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal margin_percent = 2;
     */
    marginPercent?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal discount_percent = 3;
     */
    discountPercent?: Decimal;
    /**
     * @generated from protobuf field: bool is_price_rounded_up = 4;
     */
    isPriceRoundedUp: boolean;
    /**
     * @generated from protobuf field: string reference_tier_id_string = 5;
     */
    referenceTierIdString: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 6;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 7;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 8;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID department_id = 9;
     */
    departmentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID class_id = 10;
     */
    classId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID fineline_id = 11;
     */
    finelineId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expires_at = 12;
     */
    expiresAt?: Timestamp;
    /**
     * @generated from protobuf field: string name = 13;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.price.Rule.PriceStrategy price_strategy = 14;
     */
    priceStrategy: Rule_PriceStrategy;
    /**
     * @generated from protobuf field: string original_string_id = 15;
     */
    originalStringId: string;
}
/**
 * RuleItem is a line item in the price hover state.
 *
 * @generated from protobuf message rd.price.RuleItem
 */
export interface RuleItem {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money price = 2;
     */
    price?: Money;
}
/**
 * RuleInformation contains RuleItems for the price hover state.
 *
 * @generated from protobuf message rd.price.RuleInformation
 */
export interface RuleInformation {
    /**
     * @generated from protobuf field: repeated rd.price.RuleItem rule_items = 1;
     */
    ruleItems: RuleItem[];
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID matched_product_id = 2;
     */
    matchedProductId?: UUID;
    /**
     * @generated from protobuf field: string matched_product_identifier = 3;
     */
    matchedProductIdentifier: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal quantity_break_discount = 4;
     */
    quantityBreakDiscount?: Decimal;
}
// @generated message type with reflection information, may provide speed optimized methods
class Rule$Type extends MessageType<Rule> {
    constructor() {
        super("rd.price.Rule", [
            { no: 2, name: "company_id", kind: "message", T: () => UUID },
            { no: 3, name: "product_id", kind: "message", T: () => UUID },
            { no: 4, name: "customer_id", kind: "message", T: () => UUID },
            { no: 5, name: "matched_product_id", kind: "message", T: () => UUID },
            { no: 6, name: "vendor_id", kind: "message", T: () => UUID },
            { no: 7, name: "department_id", kind: "message", T: () => UUID },
            { no: 8, name: "class_id", kind: "message", T: () => UUID },
            { no: 9, name: "fineline_id", kind: "message", T: () => UUID },
            { no: 10, name: "pricing_tier_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "reference_tier_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "price_strategy", kind: "enum", T: () => ["rd.price.Rule.PriceStrategy", Rule_PriceStrategy, "PRICE_STRATEGY_"] },
            { no: 14, name: "fixed_price", kind: "message", T: () => Money },
            { no: 15, name: "margin_percent", kind: "message", T: () => Decimal },
            { no: 16, name: "discount_percent", kind: "message", T: () => Decimal },
            { no: 17, name: "expires_at", kind: "message", T: () => Timestamp },
            { no: 18, name: "type", kind: "enum", T: () => ["rd.price.Rule.Type", Rule_Type, "TYPE_"] },
            { no: 19, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 21, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 22, name: "is_price_rounded_up", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 23, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "price_tier_type", kind: "enum", T: () => ["rd.price.Rule.PriceTierType", Rule_PriceTierType, "PRICE_TIER_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<Rule>): Rule {
        const message = { pricingTierIdString: "", referenceTierIdString: "", priceStrategy: 0, type: 0, isActive: false, isPriceRoundedUp: false, name: "", priceTierType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Rule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Rule): Rule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID company_id */ 2:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID product_id */ 3:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 4:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID matched_product_id */ 5:
                    message.matchedProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.matchedProductId);
                    break;
                case /* rd.proto.uuid.UUID vendor_id */ 6:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* rd.proto.uuid.UUID department_id */ 7:
                    message.departmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.departmentId);
                    break;
                case /* rd.proto.uuid.UUID class_id */ 8:
                    message.classId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.classId);
                    break;
                case /* rd.proto.uuid.UUID fineline_id */ 9:
                    message.finelineId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.finelineId);
                    break;
                case /* string pricing_tier_id_string */ 10:
                    message.pricingTierIdString = reader.string();
                    break;
                case /* string reference_tier_id_string */ 11:
                    message.referenceTierIdString = reader.string();
                    break;
                case /* rd.price.Rule.PriceStrategy price_strategy */ 13:
                    message.priceStrategy = reader.int32();
                    break;
                case /* rd.proto.money.Money fixed_price */ 14:
                    message.fixedPrice = Money.internalBinaryRead(reader, reader.uint32(), options, message.fixedPrice);
                    break;
                case /* rd.proto.decimal.Decimal margin_percent */ 15:
                    message.marginPercent = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.marginPercent);
                    break;
                case /* rd.proto.decimal.Decimal discount_percent */ 16:
                    message.discountPercent = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.discountPercent);
                    break;
                case /* google.protobuf.Timestamp expires_at */ 17:
                    message.expiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expiresAt);
                    break;
                case /* rd.price.Rule.Type type */ 18:
                    message.type = reader.int32();
                    break;
                case /* bool is_active */ 19:
                    message.isActive = reader.bool();
                    break;
                case /* google.protobuf.Timestamp created_at */ 20:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 21:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* bool is_price_rounded_up */ 22:
                    message.isPriceRoundedUp = reader.bool();
                    break;
                case /* string name */ 23:
                    message.name = reader.string();
                    break;
                case /* rd.price.Rule.PriceTierType price_tier_type */ 24:
                    message.priceTierType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Rule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID company_id = 2; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID product_id = 3; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 4; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID matched_product_id = 5; */
        if (message.matchedProductId)
            UUID.internalBinaryWrite(message.matchedProductId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID vendor_id = 6; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID department_id = 7; */
        if (message.departmentId)
            UUID.internalBinaryWrite(message.departmentId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID class_id = 8; */
        if (message.classId)
            UUID.internalBinaryWrite(message.classId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID fineline_id = 9; */
        if (message.finelineId)
            UUID.internalBinaryWrite(message.finelineId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string pricing_tier_id_string = 10; */
        if (message.pricingTierIdString !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.pricingTierIdString);
        /* string reference_tier_id_string = 11; */
        if (message.referenceTierIdString !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.referenceTierIdString);
        /* rd.price.Rule.PriceStrategy price_strategy = 13; */
        if (message.priceStrategy !== 0)
            writer.tag(13, WireType.Varint).int32(message.priceStrategy);
        /* rd.proto.money.Money fixed_price = 14; */
        if (message.fixedPrice)
            Money.internalBinaryWrite(message.fixedPrice, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal margin_percent = 15; */
        if (message.marginPercent)
            Decimal.internalBinaryWrite(message.marginPercent, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal discount_percent = 16; */
        if (message.discountPercent)
            Decimal.internalBinaryWrite(message.discountPercent, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp expires_at = 17; */
        if (message.expiresAt)
            Timestamp.internalBinaryWrite(message.expiresAt, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* rd.price.Rule.Type type = 18; */
        if (message.type !== 0)
            writer.tag(18, WireType.Varint).int32(message.type);
        /* bool is_active = 19; */
        if (message.isActive !== false)
            writer.tag(19, WireType.Varint).bool(message.isActive);
        /* google.protobuf.Timestamp created_at = 20; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 21; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* bool is_price_rounded_up = 22; */
        if (message.isPriceRoundedUp !== false)
            writer.tag(22, WireType.Varint).bool(message.isPriceRoundedUp);
        /* string name = 23; */
        if (message.name !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.name);
        /* rd.price.Rule.PriceTierType price_tier_type = 24; */
        if (message.priceTierType !== 0)
            writer.tag(24, WireType.Varint).int32(message.priceTierType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.price.Rule
 */
export const Rule = new Rule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Tier$Type extends MessageType<Tier> {
    constructor() {
        super("rd.price.Tier", [
            { no: 1, name: "price", kind: "message", T: () => Money },
            { no: 2, name: "margin_percent", kind: "message", T: () => Decimal },
            { no: 3, name: "price_strategy", kind: "enum", T: () => ["rd.price.Rule.PriceStrategy", Rule_PriceStrategy, "PRICE_STRATEGY_"] },
            { no: 4, name: "tier_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_price_rounded_up", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "discount_percent", kind: "message", T: () => Decimal },
            { no: 8, name: "is_reference", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "is_rule_based", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Tier>): Tier {
        const message = { priceStrategy: 0, tierIdString: "", isPriceRoundedUp: false, isReference: false, name: "", isRuleBased: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Tier>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tier): Tier {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money price */ 1:
                    message.price = Money.internalBinaryRead(reader, reader.uint32(), options, message.price);
                    break;
                case /* rd.proto.decimal.Decimal margin_percent */ 2:
                    message.marginPercent = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.marginPercent);
                    break;
                case /* rd.price.Rule.PriceStrategy price_strategy */ 3:
                    message.priceStrategy = reader.int32();
                    break;
                case /* string tier_id_string */ 4:
                    message.tierIdString = reader.string();
                    break;
                case /* bool is_price_rounded_up */ 6:
                    message.isPriceRoundedUp = reader.bool();
                    break;
                case /* rd.proto.decimal.Decimal discount_percent */ 7:
                    message.discountPercent = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.discountPercent);
                    break;
                case /* bool is_reference */ 8:
                    message.isReference = reader.bool();
                    break;
                case /* string name */ 9:
                    message.name = reader.string();
                    break;
                case /* bool is_rule_based */ 10:
                    message.isRuleBased = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tier, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money price = 1; */
        if (message.price)
            Money.internalBinaryWrite(message.price, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal margin_percent = 2; */
        if (message.marginPercent)
            Decimal.internalBinaryWrite(message.marginPercent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.price.Rule.PriceStrategy price_strategy = 3; */
        if (message.priceStrategy !== 0)
            writer.tag(3, WireType.Varint).int32(message.priceStrategy);
        /* string tier_id_string = 4; */
        if (message.tierIdString !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tierIdString);
        /* bool is_price_rounded_up = 6; */
        if (message.isPriceRoundedUp !== false)
            writer.tag(6, WireType.Varint).bool(message.isPriceRoundedUp);
        /* rd.proto.decimal.Decimal discount_percent = 7; */
        if (message.discountPercent)
            Decimal.internalBinaryWrite(message.discountPercent, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bool is_reference = 8; */
        if (message.isReference !== false)
            writer.tag(8, WireType.Varint).bool(message.isReference);
        /* string name = 9; */
        if (message.name !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.name);
        /* bool is_rule_based = 10; */
        if (message.isRuleBased !== false)
            writer.tag(10, WireType.Varint).bool(message.isRuleBased);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.price.Tier
 */
export const Tier = new Tier$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TierRule$Type extends MessageType<TierRule> {
    constructor() {
        super("rd.price.TierRule", [
            { no: 1, name: "pricing_tier_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "product_id", kind: "message", T: () => UUID },
            { no: 3, name: "department_id", kind: "message", T: () => UUID },
            { no: 4, name: "class_id", kind: "message", T: () => UUID },
            { no: 5, name: "fineline_id", kind: "message", T: () => UUID },
            { no: 6, name: "vendor_id", kind: "message", T: () => UUID },
            { no: 12, name: "price_strategy", kind: "enum", T: () => ["rd.price.Rule.PriceStrategy", Rule_PriceStrategy, "PRICE_STRATEGY_"] },
            { no: 13, name: "price", kind: "message", T: () => Money },
            { no: 14, name: "margin_percent", kind: "message", T: () => Decimal },
            { no: 15, name: "discount_percent", kind: "message", T: () => Decimal },
            { no: 16, name: "reference_tier_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "is_price_rounded_up", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "original_string_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TierRule>): TierRule {
        const message = { pricingTierIdString: "", priceStrategy: 0, referenceTierIdString: "", isPriceRoundedUp: false, name: "", originalStringId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TierRule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TierRule): TierRule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string pricing_tier_id_string */ 1:
                    message.pricingTierIdString = reader.string();
                    break;
                case /* rd.proto.uuid.UUID product_id */ 2:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* rd.proto.uuid.UUID department_id */ 3:
                    message.departmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.departmentId);
                    break;
                case /* rd.proto.uuid.UUID class_id */ 4:
                    message.classId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.classId);
                    break;
                case /* rd.proto.uuid.UUID fineline_id */ 5:
                    message.finelineId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.finelineId);
                    break;
                case /* rd.proto.uuid.UUID vendor_id */ 6:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* rd.price.Rule.PriceStrategy price_strategy */ 12:
                    message.priceStrategy = reader.int32();
                    break;
                case /* rd.proto.money.Money price */ 13:
                    message.price = Money.internalBinaryRead(reader, reader.uint32(), options, message.price);
                    break;
                case /* rd.proto.decimal.Decimal margin_percent */ 14:
                    message.marginPercent = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.marginPercent);
                    break;
                case /* rd.proto.decimal.Decimal discount_percent */ 15:
                    message.discountPercent = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.discountPercent);
                    break;
                case /* string reference_tier_id_string */ 16:
                    message.referenceTierIdString = reader.string();
                    break;
                case /* bool is_price_rounded_up */ 17:
                    message.isPriceRoundedUp = reader.bool();
                    break;
                case /* string name */ 18:
                    message.name = reader.string();
                    break;
                case /* string original_string_id */ 19:
                    message.originalStringId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TierRule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string pricing_tier_id_string = 1; */
        if (message.pricingTierIdString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pricingTierIdString);
        /* rd.proto.uuid.UUID product_id = 2; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID department_id = 3; */
        if (message.departmentId)
            UUID.internalBinaryWrite(message.departmentId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID class_id = 4; */
        if (message.classId)
            UUID.internalBinaryWrite(message.classId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID fineline_id = 5; */
        if (message.finelineId)
            UUID.internalBinaryWrite(message.finelineId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID vendor_id = 6; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.price.Rule.PriceStrategy price_strategy = 12; */
        if (message.priceStrategy !== 0)
            writer.tag(12, WireType.Varint).int32(message.priceStrategy);
        /* rd.proto.money.Money price = 13; */
        if (message.price)
            Money.internalBinaryWrite(message.price, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal margin_percent = 14; */
        if (message.marginPercent)
            Decimal.internalBinaryWrite(message.marginPercent, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal discount_percent = 15; */
        if (message.discountPercent)
            Decimal.internalBinaryWrite(message.discountPercent, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* string reference_tier_id_string = 16; */
        if (message.referenceTierIdString !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.referenceTierIdString);
        /* bool is_price_rounded_up = 17; */
        if (message.isPriceRoundedUp !== false)
            writer.tag(17, WireType.Varint).bool(message.isPriceRoundedUp);
        /* string name = 18; */
        if (message.name !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.name);
        /* string original_string_id = 19; */
        if (message.originalStringId !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.originalStringId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.price.TierRule
 */
export const TierRule = new TierRule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Custom$Type extends MessageType<Custom> {
    constructor() {
        super("rd.price.Custom", [
            { no: 1, name: "price", kind: "message", T: () => Money },
            { no: 2, name: "margin_percent", kind: "message", T: () => Decimal },
            { no: 3, name: "discount_percent", kind: "message", T: () => Decimal },
            { no: 4, name: "is_price_rounded_up", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "reference_tier_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "customer_id", kind: "message", T: () => UUID },
            { no: 7, name: "product_id", kind: "message", T: () => UUID },
            { no: 8, name: "vendor_id", kind: "message", T: () => UUID },
            { no: 9, name: "department_id", kind: "message", T: () => UUID },
            { no: 10, name: "class_id", kind: "message", T: () => UUID },
            { no: 11, name: "fineline_id", kind: "message", T: () => UUID },
            { no: 12, name: "expires_at", kind: "message", T: () => Timestamp },
            { no: 13, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "price_strategy", kind: "enum", T: () => ["rd.price.Rule.PriceStrategy", Rule_PriceStrategy, "PRICE_STRATEGY_"] },
            { no: 15, name: "original_string_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Custom>): Custom {
        const message = { isPriceRoundedUp: false, referenceTierIdString: "", name: "", priceStrategy: 0, originalStringId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Custom>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Custom): Custom {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money price */ 1:
                    message.price = Money.internalBinaryRead(reader, reader.uint32(), options, message.price);
                    break;
                case /* rd.proto.decimal.Decimal margin_percent */ 2:
                    message.marginPercent = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.marginPercent);
                    break;
                case /* rd.proto.decimal.Decimal discount_percent */ 3:
                    message.discountPercent = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.discountPercent);
                    break;
                case /* bool is_price_rounded_up */ 4:
                    message.isPriceRoundedUp = reader.bool();
                    break;
                case /* string reference_tier_id_string */ 5:
                    message.referenceTierIdString = reader.string();
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 6:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID product_id */ 7:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* rd.proto.uuid.UUID vendor_id */ 8:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* rd.proto.uuid.UUID department_id */ 9:
                    message.departmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.departmentId);
                    break;
                case /* rd.proto.uuid.UUID class_id */ 10:
                    message.classId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.classId);
                    break;
                case /* rd.proto.uuid.UUID fineline_id */ 11:
                    message.finelineId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.finelineId);
                    break;
                case /* google.protobuf.Timestamp expires_at */ 12:
                    message.expiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expiresAt);
                    break;
                case /* string name */ 13:
                    message.name = reader.string();
                    break;
                case /* rd.price.Rule.PriceStrategy price_strategy */ 14:
                    message.priceStrategy = reader.int32();
                    break;
                case /* string original_string_id */ 15:
                    message.originalStringId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Custom, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money price = 1; */
        if (message.price)
            Money.internalBinaryWrite(message.price, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal margin_percent = 2; */
        if (message.marginPercent)
            Decimal.internalBinaryWrite(message.marginPercent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal discount_percent = 3; */
        if (message.discountPercent)
            Decimal.internalBinaryWrite(message.discountPercent, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool is_price_rounded_up = 4; */
        if (message.isPriceRoundedUp !== false)
            writer.tag(4, WireType.Varint).bool(message.isPriceRoundedUp);
        /* string reference_tier_id_string = 5; */
        if (message.referenceTierIdString !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.referenceTierIdString);
        /* rd.proto.uuid.UUID customer_id = 6; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID product_id = 7; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID vendor_id = 8; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID department_id = 9; */
        if (message.departmentId)
            UUID.internalBinaryWrite(message.departmentId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID class_id = 10; */
        if (message.classId)
            UUID.internalBinaryWrite(message.classId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID fineline_id = 11; */
        if (message.finelineId)
            UUID.internalBinaryWrite(message.finelineId, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp expires_at = 12; */
        if (message.expiresAt)
            Timestamp.internalBinaryWrite(message.expiresAt, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string name = 13; */
        if (message.name !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.name);
        /* rd.price.Rule.PriceStrategy price_strategy = 14; */
        if (message.priceStrategy !== 0)
            writer.tag(14, WireType.Varint).int32(message.priceStrategy);
        /* string original_string_id = 15; */
        if (message.originalStringId !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.originalStringId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.price.Custom
 */
export const Custom = new Custom$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RuleItem$Type extends MessageType<RuleItem> {
    constructor() {
        super("rd.price.RuleItem", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "price", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<RuleItem>): RuleItem {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RuleItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RuleItem): RuleItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money price */ 2:
                    message.price = Money.internalBinaryRead(reader, reader.uint32(), options, message.price);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RuleItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money price = 2; */
        if (message.price)
            Money.internalBinaryWrite(message.price, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.price.RuleItem
 */
export const RuleItem = new RuleItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RuleInformation$Type extends MessageType<RuleInformation> {
    constructor() {
        super("rd.price.RuleInformation", [
            { no: 1, name: "rule_items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RuleItem },
            { no: 2, name: "matched_product_id", kind: "message", T: () => UUID },
            { no: 3, name: "matched_product_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "quantity_break_discount", kind: "message", T: () => Decimal }
        ]);
    }
    create(value?: PartialMessage<RuleInformation>): RuleInformation {
        const message = { ruleItems: [], matchedProductIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RuleInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RuleInformation): RuleInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.price.RuleItem rule_items */ 1:
                    message.ruleItems.push(RuleItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.uuid.UUID matched_product_id */ 2:
                    message.matchedProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.matchedProductId);
                    break;
                case /* string matched_product_identifier */ 3:
                    message.matchedProductIdentifier = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal quantity_break_discount */ 4:
                    message.quantityBreakDiscount = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.quantityBreakDiscount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RuleInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.price.RuleItem rule_items = 1; */
        for (let i = 0; i < message.ruleItems.length; i++)
            RuleItem.internalBinaryWrite(message.ruleItems[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID matched_product_id = 2; */
        if (message.matchedProductId)
            UUID.internalBinaryWrite(message.matchedProductId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string matched_product_identifier = 3; */
        if (message.matchedProductIdentifier !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchedProductIdentifier);
        /* rd.proto.decimal.Decimal quantity_break_discount = 4; */
        if (message.quantityBreakDiscount)
            Decimal.internalBinaryWrite(message.quantityBreakDiscount, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.price.RuleInformation
 */
export const RuleInformation = new RuleInformation$Type();
