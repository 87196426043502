import { Format, Money } from "./money"
import { abs, numberWithCommas } from "./helpers"

import { Decimal } from "../decimal"
import { FractionalMoney as PBFractionalMoney } from "../gen/proto/money/models_pb"

const nanoBase = 1000000000

// FractionalMoney is the class that should represent all instances of
// fractional monetary values (e.g., vendor costs, default cost, additional
// costs) in our frontend code.
export class FractionalMoney {
  private fm: PBFractionalMoney
  private constructor(fractionalMoney: PBFractionalMoney) {
    this.fm = fractionalMoney
  }

  // zero returns a zero-valued FractionalMoney.
  static zero(): FractionalMoney {
    return new FractionalMoney({ cents: 0n, nanoCents: 0n })
  }

  // fromMoney converts a Money type to a FractionalMoney type. If the input is
  // undefined, fromMoney will return zero.
  static fromMoney(m: Money): FractionalMoney {
    if (!m) {
      return FractionalMoney.zero()
    }
    return new FractionalMoney({ cents: m.cents, nanoCents: 0n })
  }

  // fromDecimal converts a Decimal type to a FractionalMoney. If decimal is
  // undefined, fromDecimal will return 0.
  static fromDecimal(d: Decimal): FractionalMoney {
    if (!d) {
      return FractionalMoney.zero()
    }
    const decimalCents: Decimal = d.mult(Decimal.fromNumber(100))
    const cents: bigint = decimalCents.int()
    const nanoCents = BigInt(
      decimalCents
        .sub(Decimal.fromNumber(Number(cents)))
        .mult(Decimal.fromNumber(nanoBase))
        .toFixed()
    )
    return FractionalMoney.fromCents(cents, nanoCents)
  }

  // fromDollars converts a Dollar value to a FractionalMoney. If dollars is
  // undefined, fromDollars will return 0.
  static fromDollars(dollars: number | string): FractionalMoney {
    if (!dollars) {
      return FractionalMoney.zero()
    }
    switch (typeof dollars) {
      case "number":
        return FractionalMoney.fromDecimal(Decimal.fromNumber(dollars))
      case "string":
        return FractionalMoney.fromDecimal(Decimal.fromString(dollars))
    }
  }

  // fromPB constructs a FractionalMoney from our protobuf FractionalMoney
  // message. If the input is undefined, fromPB will return zero.
  static fromPB(fm?: PBFractionalMoney): FractionalMoney {
    if (!fm) {
      return FractionalMoney.zero()
    }
    return new FractionalMoney({
      cents: fm.cents,
      nanoCents: fm.nanoCents,
    })
  }

  // eqFromPB is a helper function to check if two possibly undefined
  // FractionalMoneys are equal.
  static eqFromPB(fmA?: PBFractionalMoney, fmB?: PBFractionalMoney): boolean {
    if (!fmA && !fmB) {
      return true
    }

    if (!!fmA && !!fmB) {
      return fmA.cents === fmB.cents && fmA.nanoCents === fmB.nanoCents
    }

    return false
  }

  // fromCents constructs a FractionalMoney from bigints in units of cents and
  // nanocents.
  static fromCents(cents: bigint, nanoCents: bigint): FractionalMoney {
    return new FractionalMoney({ cents, nanoCents })
  }

  // toPB returns the protobuf FractionalMoney message representation defined in
  // rd/proto/money/models.proto.
  toPB(): PBFractionalMoney {
    return this.fm
  }

  // eq returns whether a FractionalMoney is equal to another FractionalMoney.
  eq(other: FractionalMoney): boolean {
    const { cents: thisCents, nanoCents: thisNanoCents } = this.fm
    const { cents: otherCents, nanoCents: otherNanoCents } = other.fm
    return thisCents === otherCents && thisNanoCents === otherNanoCents
  }

  // abs returns the absolute value of the FractionalMoney.
  abs(): FractionalMoney {
    const absCents = abs(this.fm.cents)
    const absNanoCents = abs(this.fm.nanoCents)
    return new FractionalMoney({
      cents: absCents,
      nanoCents: absNanoCents,
    })
  }

  // lt returns whether a FractionalMoney is strictly less than another
  // FractionalMoney.
  lt(other: FractionalMoney): boolean {
    if (this.fm.cents < other.fm.cents) {
      return true
    }
    if (
      this.fm.nanoCents < other.fm.nanoCents &&
      this.fm.cents <= other.fm.cents
    ) {
      return true
    }
    return false
  }

  // gt returns whether a FractionalMoney is strictly greater than another
  // FractionalMoney.
  gt(other: FractionalMoney): boolean {
    if (this.fm.cents > other.fm.cents) {
      return true
    }
    if (
      this.fm.nanoCents > other.fm.nanoCents &&
      this.fm.cents >= other.fm.cents
    ) {
      return true
    }
    return false
  }

  // gte returns whether a FractionalMoney is greater than or equal to another
  // FractionalMoney.
  gte(other: FractionalMoney): boolean {
    return !this.lt(other)
  }

  // lte returns whether a FractionalMoney is less than or equal to another
  // FractionalMoney.
  lte(other: FractionalMoney): boolean {
    return !this.gt(other)
  }

  // add returns a new FractionalMoney representing the sum of this and other.
  add(other?: FractionalMoney): FractionalMoney {
    const cents = this.fm.cents + (other?.fm.cents ?? 0n)
    const nanoCents = this.fm.nanoCents + (other?.fm.nanoCents ?? 0n)
    return new FractionalMoney({ cents, nanoCents })
  }

  // sub returns a new FractionalMoney representing the difference of this and
  // other.
  sub(other?: FractionalMoney): FractionalMoney {
    const cents = this.fm.cents - (other?.fm.cents ?? 0n)
    const nanoCents = this.fm.nanoCents - (other?.fm.nanoCents ?? 0n)
    return new FractionalMoney({ cents, nanoCents })
  }

  // centsAsDecimal returns the FractionalMoney as a Decimal.
  centsAsDecimal(): Decimal {
    const nanoBaseDecimal = Decimal.fromString(nanoBase.toString())
    return Decimal.fromString(this.fm.cents.toString()).add(
      Decimal.fromString(this.fm.nanoCents.toString()).div(nanoBaseDecimal)
    )
  }

  // roundToNearestCent rounds the fractional money to the nearest cent.
  roundToNearestCent(): FractionalMoney {
    let cents = this.fm.cents
    let nanoCents = this.fm.nanoCents
    if (nanoCents > 500000000) {
      cents++
    }
    if (nanoCents < -500000000) {
      cents--
    }
    nanoCents = 0n
    return new FractionalMoney({ cents, nanoCents })
  }

  // roundToNearestTenthOfACent rounds the fractional money to the nearest
  // tenth of a cent.
  roundToNearestTenthOfACent(): FractionalMoney {
    const baseToRoundTenthsOfACent = 100000000n
    const cents = this.fm.cents
    let nanoCents = this.fm.nanoCents
    let tenthOfACent = nanoCents / baseToRoundTenthsOfACent

    const trunc = nanoCents - tenthOfACent * baseToRoundTenthsOfACent
    if (trunc > 50000000n) {
      tenthOfACent++
    }
    if (trunc < -50000000n) {
      tenthOfACent--
    }
    nanoCents = BigInt(tenthOfACent) * baseToRoundTenthsOfACent

    return new FractionalMoney({ cents, nanoCents })
  }

  // ToMoney converts a FractionalMoney to a Money by rounding to the nearest
  // cent.
  toMoney(): Money {
    const rounded = this.roundToNearestCent()
    return Money.fromCents(rounded.fm.cents)
  }

  // format formats the FractionalMoney as a string (e.g., $12.3344, $0.00,
  // $0.10).
  format(f?: Format): string {
    let isNegative = false
    if (this.fm.cents < 0 || this.fm.nanoCents < 0) {
      isNegative = true
    }
    const absFractionalMoney: FractionalMoney = new FractionalMoney({
      cents: abs(this.fm.cents),
      nanoCents: abs(this.fm.nanoCents),
    })
    const totalCentsDecimal = absFractionalMoney.centsAsDecimal()
    let totalStr = totalCentsDecimal.div(Decimal.fromNumber(100)).toString()
    let prefix = isNegative ? "-$" : "$"
    let suffix = ""
    switch (f) {
      case Format.ACCOUNTING:
        prefix = isNegative ? "($" : "$"
        suffix = isNegative ? ")" : ""
        break
      case Format.NO_DOLLAR_SIGN:
        prefix = isNegative ? "-" : ""
        break
      case Format.ABSOLUTE:
        prefix = "$"
        break
    }

    const split = totalStr.split(".")
    if (split.length === 1) {
      totalStr += ".00"
    }
    if (split.length == 2 && split[1].length === 1) {
      totalStr += "0"
    }

    return `${prefix}${numberWithCommas(totalStr)}${suffix}`
  }

  // mult returns a new FractionalMoney representing the product of this and the given decimal.
  mult(d: Decimal): FractionalMoney {
    const fmDecimal: Decimal = this.centsAsDecimal()
    const product: Decimal = d.mult(fmDecimal).div(Decimal.fromNumber(100))
    return FractionalMoney.fromDecimal(product)
  }
}
