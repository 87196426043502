import { ComponentPropsWithRef, MouseEventHandler, forwardRef } from "react"

import { classNames } from "lib/classNames"

interface TextButtonProps extends ComponentPropsWithRef<"button"> {
  enableClickPropagation?: boolean
}

export const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
  (props, ref) => {
    const {
      children,
      className,
      onClick,
      enableClickPropagation = false,
      ...otherProps
    } = props

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      if (!enableClickPropagation) {
        event.stopPropagation()
      }
      if (onClick) {
        onClick(event)
      }
    }

    return (
      <button
        ref={ref}
        type="button"
        className={classNames(
          "text-cobalt-400 hover:text-cobalt-300 active:text-cobalt-600 dark:text-cobalt-200 dark:hover:text-cobalt-300 dark:active:text-cobalt-100 whitespace-nowrap text-sm font-semibold focus:outline-none disabled:text-gray-300 dark:disabled:text-gray-600",
          className
        )}
        onClick={handleClick}
        {...otherProps}
      >
        {children}
      </button>
    )
  }
)

TextButton.displayName = "TextButton"
