import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { OrderShipment_CostAdjustment as CostAdjustment } from "gen/txn/models_pb"
import { FractionalMoney } from "money"
import { Money } from "money"
import { Timestamp } from "lib/timestamp"
import { UUID } from "uuid-rd"
import { useCallback } from "react"

export interface OrderShipmentState {
  receiveNotes: string
  costsByProductId: Record<string, FractionalMoney>
  costAdjustments: CostAdjustment[]
  invoiceDate: Date | null
  dueDate: Date | null
  invoiceNumber: string
}

export interface OrderShipmentFormState {
  original: OrderShipmentState
  current: OrderShipmentState
  isInitialized: boolean
}

export function emptyState(): OrderShipmentState {
  return {
    receiveNotes: "",
    costsByProductId: {},
    costAdjustments: [],
    invoiceDate: null,
    dueDate: null,
    invoiceNumber: "",
  }
}

export function emptyFormState(): OrderShipmentFormState {
  return {
    original: emptyState(),
    current: emptyState(),
    isInitialized: false,
  }
}

export const orderShipmentSlice = createSlice({
  name: "orderShipment",
  initialState: emptyFormState(),
  reducers: {
    setInitialState: (
      state,
      { payload: initialState }: PayloadAction<OrderShipmentState>
    ) => {
      state.original = initialState
      state.current = initialState
      state.isInitialized = true
    },
    setReceiveNotes: (
      { current },
      { payload: receiveNotes }: PayloadAction<string>
    ) => {
      current.receiveNotes = receiveNotes
    },
    setProductCost: (
      { current },
      {
        payload: { productId, cost },
      }: PayloadAction<{
        productId: UUID
        cost: FractionalMoney
      }>
    ) => {
      const productIdStr = productId.toString()
      if (!(productIdStr in current.costsByProductId)) {
        return
      }
      current.costsByProductId[productIdStr] = cost
    },
    setCostAdjustments: (
      { current },
      { payload: costAdjustments }: PayloadAction<CostAdjustment[]>
    ) => {
      current.costAdjustments = costAdjustments
    },
    setInvoiceDate: (
      { current },
      { payload: invoiceDate }: PayloadAction<Date | null>
    ) => {
      current.invoiceDate = invoiceDate
    },
    setDueDate: (
      { current },
      { payload: dueDate }: PayloadAction<Date | null>
    ) => {
      current.dueDate = dueDate
    },
    setInvoiceNumber: (
      { current },
      { payload: invoiceNumber }: PayloadAction<string>
    ) => {
      current.invoiceNumber = invoiceNumber
    },
  },
})

export const {
  setInitialState,
  setReceiveNotes,
  setProductCost,
  setCostAdjustments,
  setInvoiceDate,
  setDueDate,
  setInvoiceNumber,
} = orderShipmentSlice.actions

export function selectIsEdited({
  orderShipments: { original, current },
}: AppState) {
  return [
    current.receiveNotes === original.receiveNotes,
    Timestamp.eqFromDate(current.dueDate, original.dueDate),
    Timestamp.eqFromDate(current.invoiceDate, original.invoiceDate),
    current.invoiceNumber === original.invoiceNumber,
    isProductCostsEqual(current.costsByProductId, original.costsByProductId),
    isCostAdjustmentsEqual(current.costAdjustments, original.costAdjustments),
  ].some((isEdited) => !isEdited)
}

export const selectReceiveNotes = (state: AppState) =>
  state.orderShipments.current.receiveNotes

export const useSelectProductCost = (productId: UUID) => {
  const productIdStr = productId.toString()

  return useCallback(
    (state: AppState): FractionalMoney =>
      state.orderShipments.current.costsByProductId[productIdStr] ??
      FractionalMoney.zero(),
    [productIdStr]
  )
}
export function selectCostAdjustments({
  orderShipments: { current },
}: AppState) {
  return current.costAdjustments
}

function isProductCostsEqual(
  a: Record<string, FractionalMoney>,
  b: Record<string, FractionalMoney>
) {
  return (
    Object.keys(a).length === Object.keys(b).length &&
    Object.keys(a).every(
      (productId) =>
        productId in a && productId in b && a[productId].eq(b[productId])
    )
  )
}

function isCostAdjustmentsEqual(a: CostAdjustment[], b: CostAdjustment[]) {
  return (
    a.length === b.length &&
    a.every((_, i) => isCostAdjustmentEqual(a[i], b[i]))
  )
}

function isCostAdjustmentEqual(a: CostAdjustment, b: CostAdjustment) {
  if (a.name !== b.name) {
    return false
  }

  if (!Money.fromPB(a.amount).eq(Money.fromPB(b.amount))) {
    return false
  }

  if (!UUID.eqFromPB(a.chartOfAccountId, b.chartOfAccountId)) {
    return false
  }

  return true
}
