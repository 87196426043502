export const REPORT_PARAM = "report"
export const END_DATE_PARAM = "end"
export const DATE_RANGE_PARAM = "dateRange"
export const SORT_PARAM = "sort"
export const SORT_ORDER_PARAM = "sortOrder"
export const LOCATION_PARAM = "location"
export const CLERK_PARAM = "clerk"
export const REP_PARAM = "rep"
export const CUSTOMER_PARAM = "customer"
export const PRODUCT_PARAM = "product"
export const PARAM_DELIMITER = ","
export const START_DATE_PARAM = "start"
export const VENDOR_PARAM = "vendor"
export const DEPARTMENT_PARAM = "department"
export const CLASS_PARAM = "class"
export const FINELINE_PARAM = "fineline"
export const ACCOUNT_PARAM = "account"
export const SALES_METHOD_PARAM = "salesMethod"
export const SALES_TRANSACTION_TYPE_PARAM = "salesTransactionType"
export const PAYMENT_METHOD_PARAM = "paymentMethod"
export const PAYMENT_TRANSACTION_TYPE_PARAM = "paymentTransactionType"
export const CASH_REGISTER_TRANSACTION_TYPE_PARAM =
  "cashRegisterTransactionType"
export const AGING_DETAIL_TRANSACTION_TYPE_PARAM =
  "aging-detail-transaction-type"
export const AGING_DETAIL_STATUS_PARAM = "aging-detail-status"
