/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "search/cache/models.proto" (package "rd.search.cache", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { UUID } from "../../proto/uuid/models_pb";
import { Any } from "../../google/protobuf/any_pb";
/**
 * Update represents a record with which to update search.
 *
 * @generated from protobuf message rd.search.cache.Update
 */
export interface Update {
    /**
     * @generated from protobuf field: google.protobuf.Any record = 1;
     */
    record?: Any;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 2;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: bool delete = 3;
     */
    delete: boolean;
    /**
     * @generated from protobuf field: string pubsub_token = 4;
     */
    pubsubToken: string;
    /**
     * @generated from protobuf field: string pubsub_origin = 5;
     */
    pubsubOrigin: string;
    /**
     * @generated from protobuf field: bool disk_only = 6;
     */
    diskOnly: boolean; // for metarecords, only process in disk handler
}
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
    constructor() {
        super("rd.search.cache.Update", [
            { no: 1, name: "record", kind: "message", T: () => Any },
            { no: 2, name: "company_id", kind: "message", T: () => UUID },
            { no: 3, name: "delete", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "pubsub_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "pubsub_origin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "disk_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Update>): Update {
        const message = { delete: false, pubsubToken: "", pubsubOrigin: "", diskOnly: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Update>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Update): Update {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Any record */ 1:
                    message.record = Any.internalBinaryRead(reader, reader.uint32(), options, message.record);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 2:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* bool delete */ 3:
                    message.delete = reader.bool();
                    break;
                case /* string pubsub_token */ 4:
                    message.pubsubToken = reader.string();
                    break;
                case /* string pubsub_origin */ 5:
                    message.pubsubOrigin = reader.string();
                    break;
                case /* bool disk_only */ 6:
                    message.diskOnly = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Update, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Any record = 1; */
        if (message.record)
            Any.internalBinaryWrite(message.record, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 2; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool delete = 3; */
        if (message.delete !== false)
            writer.tag(3, WireType.Varint).bool(message.delete);
        /* string pubsub_token = 4; */
        if (message.pubsubToken !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.pubsubToken);
        /* string pubsub_origin = 5; */
        if (message.pubsubOrigin !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.pubsubOrigin);
        /* bool disk_only = 6; */
        if (message.diskOnly !== false)
            writer.tag(6, WireType.Varint).bool(message.diskOnly);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.cache.Update
 */
export const Update = new Update$Type();
