import { FinanceChargeSetting, Staff } from "gen/company/models_pb"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { BoolFilter } from "gen/search/service/service_pb"

export interface CustomerSearchFiltersState {
  filters: {
    status: BoolFilter[]
    pricingTier: string[]
    financingTerm: string[]
    financeChargeSetting: FinanceChargeSetting[]
    salesRep: Staff[]
    creditOnHold: BoolFilter[]
  }
  searchQuery: string
}

const initialState: CustomerSearchFiltersState = {
  filters: {
    status: [BoolFilter.TRUE],
    pricingTier: [],
    financingTerm: [],
    financeChargeSetting: [],
    salesRep: [],
    creditOnHold: [],
  },
  searchQuery: "",
}

export const customersSearchSlice = createSlice({
  name: "customersSearch",
  initialState,
  reducers: {
    resetCustomersSearchFilters: () => initialState,
    setCustomersSearchStatusFilters: (
      state,
      action: PayloadAction<BoolFilter[]>
    ) => {
      state.filters.status = action.payload
    },
    setCustomersSearchCreditOnHoldFilters: (
      state,
      action: PayloadAction<BoolFilter[]>
    ) => {
      state.filters.creditOnHold = action.payload
    },
    setCustomersSearchPricingTierFilters: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.filters.pricingTier = action.payload
    },
    setCustomersSearchFinancingTermFilters: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.filters.financingTerm = action.payload
    },
    setCustomersSearchFinanceChargeSettingsFilters: (
      state,
      action: PayloadAction<FinanceChargeSetting[]>
    ) => {
      state.filters.financeChargeSetting = action.payload
    },
    setCustomersSearchSalesRepFilters: (
      state,
      action: PayloadAction<Staff[]>
    ) => {
      state.filters.salesRep = action.payload
    },
    setCustomersSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
  },
})

export const {
  resetCustomersSearchFilters,
  setCustomersSearchStatusFilters,
  setCustomersSearchCreditOnHoldFilters,
  setCustomersSearchPricingTierFilters,
  setCustomersSearchFinancingTermFilters,
  setCustomersSearchFinanceChargeSettingsFilters,
  setCustomersSearchSalesRepFilters,
  setCustomersSearchQuery,
} = customersSearchSlice.actions

export const selectCustomersSearchStatusFilters = (state: AppState) =>
  state.customersSearch.filters.status
export const selectCustomersSearchCreditOnHoldFilters = (state: AppState) =>
  state.customersSearch.filters.creditOnHold
export const selectCustomersSearchPricingTierFilters = (state: AppState) =>
  state.customersSearch.filters.pricingTier
export const selectCustomersSearchFinancingTermFilters = (state: AppState) =>
  state.customersSearch.filters.financingTerm
export const selectCustomersSearchFinanceChargeSettingsFilters = (
  state: AppState
) => state.customersSearch.filters.financeChargeSetting
export const selectCustomersSearchSalesRepFilters = (state: AppState) =>
  state.customersSearch.filters.salesRep
export const selectCustomersSearchQuery = (state: AppState) =>
  state.customersSearch.searchQuery
