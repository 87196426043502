// @ts-strict-ignore
import { Button, ButtonProps } from "../Button"
import {
  ComponentPropsWithRef,
  FocusEventHandler,
  ReactNode,
  forwardRef,
  useId,
  useImperativeHandle,
  useRef,
} from "react"
import { Label, LabelProps } from "components/Label"

import InputMask from "react-input-mask"
import { classNames } from "lib/classNames"

type TextInputSuffix = "days" | "%" | "seconds" | "minutes"

export type TextInputProps = ComponentPropsWithRef<"input"> &
  LabelProps & {
    invalid?: boolean
    mask?: string
    prefix?: string
    innerPrefix?: "$" | ReactNode
    suffix?: "days" | "%" | "seconds" | "minutes" | ""
    suffixGrossMargin?: string
    suffixGrossMarginPrice?: string
    suffixButtonTitle?: string
    suffixButtonProps?: Partial<ButtonProps>
    suffixOfAmount?: string
    RightView?: ReactNode
    selectOnFocus?: boolean
  }

function getSuffixPadding(suffix: TextInputSuffix) {
  switch (suffix) {
    case "days":
      return "pr-14"
    case "%":
      return "pr-8"
    case "seconds":
      return "pr-20"
    case "minutes":
      return "pr-20"
    default:
      throw new Error("unexpected suffix")
  }
}

function getSuffixGrossMarginPadding(suffixGrossMargin: string) {
  if (suffixGrossMargin) {
    return "pl-8"
  }

  return ""
}

function getInnerPrefixPadding(innerPrefix: string | ReactNode) {
  if (innerPrefix instanceof String) {
    switch (innerPrefix) {
      case "$":
        return "pl-8"
      default:
        throw new Error("unexpected inner prefix")
    }
  } else {
    return "pl-10"
  }
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      label,
      secondaryLabel,
      subLabel,
      className,
      invalid = false,
      errorMessage,
      mask,
      prefix,
      innerPrefix,
      suffix,
      suffixButtonTitle,
      suffixButtonProps,
      suffixGrossMargin,
      suffixGrossMarginPrice,
      suffixOfAmount,
      RightView,
      containerClassName,
      onFocus,
      selectOnFocus = true,
      ...otherProps
    } = props

    const id = useId()

    const innerRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement)

    const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
      if (selectOnFocus) {
        e.target.select()
      }

      if (onFocus) {
        onFocus(e)
      }
    }

    return (
      <Label
        label={label}
        subLabel={subLabel}
        secondaryLabel={secondaryLabel}
        errorMessage={errorMessage}
        inputId={otherProps.id ?? id}
        containerClassName={containerClassName}
      >
        <div
          className={classNames(
            "flex",
            "items-center",
            !!suffix ||
              !!suffixGrossMargin ||
              !!suffixGrossMarginPrice ||
              RightView ||
              !!innerPrefix
              ? "relative"
              : ""
          )}
        >
          {!!prefix && (
            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-200 bg-gray-50 px-3 py-2 text-base text-gray-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300">
              {prefix}
            </span>
          )}
          {!!innerPrefix && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-black dark:text-white">
              {innerPrefix instanceof String ? (
                <span className="sm:text-base">{innerPrefix}</span>
              ) : (
                innerPrefix
              )}
            </div>
          )}
          {mask ? (
            <InputMask
              id={otherProps.id ?? id}
              mask={mask}
              ref={innerRef}
              type="text"
              className={classNames(
                "focus:border-cobalt-400 focus:ring-cobalt-400 dark:focus:border-cobalt-300 dark:focus:ring-cobalt-300 w-full rounded bg-white text-base text-gray-600 placeholder:text-gray-600 invalid:border-red-400 focus:text-black disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-500 dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-200 dark:invalid:border-red-900 dark:focus:text-white dark:disabled:border-gray-600 dark:disabled:bg-gray-700 dark:disabled:text-gray-300",
                invalid || !!errorMessage
                  ? "border-red-400 dark:border-red-900"
                  : "border-gray-200 dark:border-gray-600",
                prefix && "rounded-none rounded-r",
                !!innerPrefix && getInnerPrefixPadding(innerPrefix),
                suffix ? getSuffixPadding(suffix) : "",
                suffixGrossMargin
                  ? getSuffixGrossMarginPadding(suffixGrossMargin)
                  : "",
                suffixGrossMarginPrice
                  ? getSuffixGrossMarginPadding(suffixGrossMarginPrice)
                  : "",
                suffixButtonTitle && "rounded-none rounded-l focus:z-10",
                className
              )}
              onFocus={handleFocus}
              {...otherProps}
            />
          ) : (
            <input
              ref={innerRef}
              type="text"
              aria-invalid={invalid}
              className={classNames(
                "focus:border-cobalt-400 focus:ring-cobalt-400 dark:focus:border-cobalt-300 dark:focus:ring-cobalt-300 w-full rounded bg-white text-base text-gray-600 placeholder:text-gray-600 focus:text-black disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-500 dark:bg-gray-800 dark:text-gray-200 dark:placeholder:text-gray-200 dark:focus:text-white dark:disabled:border-gray-600 dark:disabled:bg-gray-700 dark:disabled:text-gray-300",
                invalid || !!errorMessage
                  ? "border-red-400 dark:border-red-900"
                  : "border-gray-200 dark:border-gray-600",
                prefix && "rounded-none rounded-r",
                !!innerPrefix && getInnerPrefixPadding(innerPrefix),
                suffix ? getSuffixPadding(suffix) : "",
                suffixGrossMargin
                  ? getSuffixGrossMarginPadding(suffixGrossMargin)
                  : "",
                suffixGrossMarginPrice
                  ? getSuffixGrossMarginPadding(suffixGrossMarginPrice)
                  : "",
                suffixButtonTitle && "rounded-none rounded-l focus:z-10",
                !!suffixButtonTitle &&
                  !invalid &&
                  !errorMessage &&
                  "border-r-gray-300",
                className
              )}
              onFocus={handleFocus}
              id={otherProps.id ?? id}
              {...otherProps}
            />
          )}
          {!!suffix && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
              <span className="text-gray-600 dark:text-gray-300 sm:text-sm">
                {suffix}
              </span>
            </div>
          )}
          {!!suffixGrossMargin && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-6">
              <span className="text-gray-600 dark:text-gray-300 sm:text-sm">
                {suffixGrossMargin}
              </span>
            </div>
          )}
          {!!suffixGrossMarginPrice && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-6">
              <span className="text-gray-600 dark:text-gray-300 sm:text-sm">
                {suffixGrossMarginPrice}
              </span>
            </div>
          )}
          {!!suffixOfAmount && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-6">
              <span className="text-gray-600 dark:text-gray-300 sm:text-sm">
                {suffixOfAmount}
              </span>
            </div>
          )}
          {!!suffixButtonTitle && (
            <Button
              mode="secondary"
              className="inline-flex cursor-pointer items-center rounded-l-none rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 py-2 text-sm font-bold text-black dark:border-gray-600 dark:bg-gray-700 dark:text-white"
              {...suffixButtonProps}
            >
              {suffixButtonTitle}
            </Button>
          )}
          {!!RightView && RightView}
        </div>
      </Label>
    )
  }
)

TextInput.displayName = "TextInput"
