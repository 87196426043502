import { parse, stringify, v4 } from "uuid-alias"

import { UUID as PBUUID } from "gen/proto/uuid/models_pb"

// UUID is a simple wrapper for our UUID protobuf message.
export class UUID {
  private bytes: Uint8Array

  private constructor(bytes: Uint8Array) {
    this.bytes = bytes
  }

  // fromPB constructs a UUID from our protobuf UUID message defined
  // in rd/proto/uuid/models.proto.
  static fromPB(pbUUID?: PBUUID): UUID {
    return new UUID(pbUUID?.bytes ?? new Uint8Array())
  }

  // fromBytes constructs a UUID from a byte array.
  static fromBytes(bytes: Uint8Array) {
    return new UUID(bytes)
  }

  static idString(id?: PBUUID): string {
    if (!id) {
      return ""
    }
    return UUID.fromPB(id).toString()
  }

  // fromString constructs a UUID from its canonical string representation.
  static fromString(str: string): UUID {
    return new UUID(new Uint8Array(parse(str)))
  }

  // eqFromPB is a helper function to check if two possibly undefined PBUUIDs are equal
  static eqFromPB(idA?: PBUUID, idB?: PBUUID): boolean {
    if (!idA && !idB) {
      return true
    }

    if (!!idA?.bytes && !!idB?.bytes) {
      return UUID.fromPB(idA).eq(UUID.fromPB(idB))
    }

    return false
  }

  // comparePB is a helper intended for sort comparisons of 2 objects with PBUUID id's
  // deprioritizes
  static comparePB(idA?: PBUUID, idB?: PBUUID) {
    if (idA === idB) {
      return 0
    }
    if (!idA?.bytes && !idB?.bytes) {
      return 0
    }
    if (!idA?.bytes) {
      return -1
    }
    if (!idB?.bytes) {
      return 1
    }
    if (idA.bytes.length !== idB.bytes.length) {
      return idA.bytes.length > idB.bytes.length ? 1 : -1
    }
    for (const idx of Array(idA.bytes.length).keys()) {
      const byteA = idA.bytes[idx]
      const byteB = idB.bytes[idx]
      if (byteA === byteB) {
        continue
      }
      return byteA > byteB ? 1 : -1
    }

    return 0
  }

  // newRandom returns a random uuid object.
  static newRandom(): UUID {
    return UUID.fromString(v4())
  }

  // toString returns the UUID's canonical string representation.
  toString(): string {
    try {
      return stringify(this.bytes)
    } catch {
      return ""
    }
  }

  // toBytes returns the UUID's underlying bytes.
  toBytes(): Uint8Array {
    return this.bytes
  }

  // eq returns whether a UUID is equal to another UUID.
  eq(other: UUID): boolean {
    if (this.bytes.length !== other.bytes.length) {
      return false
    }

    return this.bytes.every((byte, i) => byte === other.bytes[i])
  }

  // toPB returns the protobuf UUID message representation defined in
  // rd/proto/uuid/models.proto.
  toPB(): PBUUID {
    return {
      bytes: this.bytes,
    }
  }
}
