/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "search/service/service.proto" (package "rd.search.service", syntax proto3)
// tslint:disable
// @ts-nocheck
import { CxaSearchService } from "./service_pb";
import type { CxaSearchReturnsRes } from "./service_pb";
import type { CxaSearchReturnsReq } from "./service_pb";
import type { CxaSearchOutstandingSalesRes } from "./service_pb";
import type { CxaSearchOutstandingSalesReq } from "./service_pb";
import type { CxaSearchSalesRes } from "./service_pb";
import type { CxaSearchSalesReq } from "./service_pb";
import { SearchalyticsService } from "./service_pb";
import type { GetAgingDetailCSVRes } from "./service_pb";
import type { GetAgingDetailCSVReq } from "./service_pb";
import type { GetAgingDetailPDFRes } from "./service_pb";
import type { GetAgingDetailPDFReq } from "./service_pb";
import type { GetAgingDetailRes } from "./service_pb";
import type { GetAgingDetailReq } from "./service_pb";
import type { GetNewAccountsOpenedCSVRes } from "./service_pb";
import type { GetNewAccountsOpenedCSVReq } from "./service_pb";
import type { GetNewAccountsOpenedPDFRes } from "./service_pb";
import type { GetNewAccountsOpenedPDFReq } from "./service_pb";
import type { GetNewAccountsOpenedRes } from "./service_pb";
import type { GetNewAccountsOpenedReq } from "./service_pb";
import type { GetProfitAndLossPDFRes } from "./service_pb";
import type { GetProfitAndLossPDFReq } from "./service_pb";
import type { GetProfitAndLossRes } from "./service_pb";
import type { GetProfitAndLossReq } from "./service_pb";
import type { GetCustomerAgingCSVRes } from "../../api/analytics/service_pb";
import type { GetCustomerAgingCSVReq } from "../../api/analytics/service_pb";
import type { GetCustomerAgingPDFRes } from "../../api/analytics/service_pb";
import type { GetCustomerAgingPDFReq } from "../../api/analytics/service_pb";
import type { GetCustomerAgingRes } from "../../api/analytics/service_pb";
import type { GetCustomerAgingReq } from "../../api/analytics/service_pb";
import type { GetSalesByProductCSVRes } from "./service_pb";
import type { GetSalesByProductCSVReq } from "./service_pb";
import type { GetSalesByProductPDFRes } from "./service_pb";
import type { GetSalesByProductPDFReq } from "./service_pb";
import type { GetSalesByProductRes } from "./service_pb";
import type { GetSalesByProductReq } from "./service_pb";
import type { GetSalesByCustomerCSVRes } from "./service_pb";
import type { GetSalesByCustomerCSVReq } from "./service_pb";
import type { GetSalesByCustomerPDFRes } from "./service_pb";
import type { GetSalesByCustomerPDFReq } from "./service_pb";
import type { GetSalesByCustomerRes } from "./service_pb";
import type { GetSalesByCustomerReq } from "./service_pb";
import type { GetSoldProductsCSVRes } from "./service_pb";
import type { GetSoldProductsCSVReq } from "./service_pb";
import type { GetSoldProductsPDFRes } from "./service_pb";
import type { GetSoldProductsPDFReq } from "./service_pb";
import type { GetSoldProductsRes } from "./service_pb";
import type { GetSoldProductsReq } from "./service_pb";
import type { GetPaymentsCSVRes } from "./service_pb";
import type { GetPaymentsCSVReq } from "./service_pb";
import type { GetPaymentsPDFRes } from "./service_pb";
import type { GetPaymentsPDFReq } from "./service_pb";
import type { GetPaymentsRes } from "./service_pb";
import type { GetPaymentsReq } from "./service_pb";
import type { GetSalesCSVRes } from "./service_pb";
import type { GetSalesCSVReq } from "./service_pb";
import type { GetSalesPDFRes } from "./service_pb";
import type { GetSalesPDFReq } from "./service_pb";
import type { GetSalesRes } from "./service_pb";
import type { GetSalesReq } from "./service_pb";
import type { GetCashRegisterCSVRes } from "./service_pb";
import type { GetCashRegisterCSVReq } from "./service_pb";
import type { GetCashRegisterPDFRes } from "./service_pb";
import type { GetCashRegisterPDFReq } from "./service_pb";
import type { GetCashRegisterRes } from "./service_pb";
import type { GetCashRegisterReq } from "./service_pb";
import type { GetSalesByDayCSVRes } from "../../api/analytics/service_pb";
import type { GetSalesByDayCSVReq } from "../../api/analytics/service_pb";
import type { GetSalesByDayPDFRes } from "../../api/analytics/service_pb";
import type { GetSalesByDayPDFReq } from "../../api/analytics/service_pb";
import type { GetSalesByDayRes } from "../../api/analytics/service_pb";
import type { GetSalesByDayReq } from "../../api/analytics/service_pb";
import { BulkService } from "./service_pb";
import type { BulkExportVendorsRes } from "./service_pb";
import type { BulkExportVendorsReq } from "./service_pb";
import type { BulkExportProductLabelsRes } from "./service_pb";
import type { BulkExportProductLabelsReq } from "./service_pb";
import type { BulkExportProductsRes } from "./service_pb";
import type { BulkExportProductsReq } from "./service_pb";
import type { BulkExportCustomersRes } from "./service_pb";
import type { BulkExportCustomersReq } from "./service_pb";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { SearchService } from "./service_pb";
import type { SearchPriceTierRulesRes } from "./service_pb";
import type { SearchPriceTierRulesReq } from "./service_pb";
import type { SearchCustomPriceRulesRes } from "./service_pb";
import type { SearchCustomPriceRulesReq } from "./service_pb";
import type { SearchInventoryChangesRes } from "./service_pb";
import type { SearchInventoryChangesReq } from "./service_pb";
import type { SearchOrderShipmentsForReconciliationRes } from "./service_pb";
import type { SearchOrderShipmentsForReconciliationReq } from "./service_pb";
import type { ReloadRes } from "./service_pb";
import type { ReloadReq } from "./service_pb";
import type { UpdateRecordRes } from "./service_pb";
import type { UpdateRecordReq } from "./service_pb";
import type { SearchFinancialTransactionsRes } from "./service_pb";
import type { SearchFinancialTransactionsReq } from "./service_pb";
import type { SearchCashTransactionsRes } from "./service_pb";
import type { SearchCashTransactionsReq } from "./service_pb";
import type { SearchVendorsRes } from "./service_pb";
import type { SearchVendorsReq } from "./service_pb";
import type { SearchTransfersRes } from "./service_pb";
import type { SearchTransfersReq } from "./service_pb";
import type { SearchTintColorsRes } from "./service_pb";
import type { SearchTintColorsReq } from "./service_pb";
import type { SearchOutstandingSalesRes } from "./service_pb";
import type { SearchOutstandingSalesReq } from "./service_pb";
import type { SearchReturnsRes } from "./service_pb";
import type { SearchReturnsReq } from "./service_pb";
import type { SearchSalesV2Res } from "./service_pb";
import type { SearchSalesV2Req } from "./service_pb";
import type { SearchProductsV2Res } from "./service_pb";
import type { SearchProductsV2Req } from "./service_pb";
import type { SearchOrderShipmentsRes } from "./service_pb";
import type { SearchOrderShipmentsReq } from "./service_pb";
import type { SearchOrdersRes } from "./service_pb";
import type { SearchOrdersReq } from "./service_pb";
import type { SearchExcludedInventoryCountProductsRes } from "./service_pb";
import type { SearchExcludedInventoryCountProductsReq } from "./service_pb";
import type { SearchInventoryCountProductsRes } from "./service_pb";
import type { SearchInventoryCountProductsReq } from "./service_pb";
import type { SearchExternalTransactionsRes } from "./service_pb";
import type { SearchExternalTransactionsReq } from "./service_pb";
import type { SearchCustomersV2Res } from "./service_pb";
import type { SearchCustomersV2Req } from "./service_pb";
import type { SearchRefundedBillPaymentsRes } from "./service_pb";
import type { SearchRefundedBillPaymentsReq } from "./service_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { SearchBillPaymentsRes } from "./service_pb";
import type { SearchBillPaymentsReq } from "./service_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rd.search.service.SearchService
 */
export interface ISearchServiceClient {
    /**
     * @generated from protobuf rpc: SearchBillPayments(rd.search.service.SearchBillPaymentsReq) returns (rd.search.service.SearchBillPaymentsRes);
     */
    searchBillPayments(input: SearchBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchBillPaymentsReq, SearchBillPaymentsRes>;
    /**
     * @generated from protobuf rpc: SearchRefundedBillPayments(rd.search.service.SearchRefundedBillPaymentsReq) returns (rd.search.service.SearchRefundedBillPaymentsRes);
     */
    searchRefundedBillPayments(input: SearchRefundedBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchRefundedBillPaymentsReq, SearchRefundedBillPaymentsRes>;
    /**
     * @generated from protobuf rpc: SearchCustomersV2(rd.search.service.SearchCustomersV2Req) returns (rd.search.service.SearchCustomersV2Res);
     */
    searchCustomersV2(input: SearchCustomersV2Req, options?: RpcOptions): UnaryCall<SearchCustomersV2Req, SearchCustomersV2Res>;
    /**
     * @generated from protobuf rpc: SearchExternalTransactions(rd.search.service.SearchExternalTransactionsReq) returns (rd.search.service.SearchExternalTransactionsRes);
     */
    searchExternalTransactions(input: SearchExternalTransactionsReq, options?: RpcOptions): UnaryCall<SearchExternalTransactionsReq, SearchExternalTransactionsRes>;
    /**
     * @generated from protobuf rpc: SearchInventoryCountProducts(rd.search.service.SearchInventoryCountProductsReq) returns (rd.search.service.SearchInventoryCountProductsRes);
     */
    searchInventoryCountProducts(input: SearchInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchInventoryCountProductsReq, SearchInventoryCountProductsRes>;
    /**
     * @generated from protobuf rpc: SearchExcludedInventoryCountProducts(rd.search.service.SearchExcludedInventoryCountProductsReq) returns (rd.search.service.SearchExcludedInventoryCountProductsRes);
     */
    searchExcludedInventoryCountProducts(input: SearchExcludedInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchExcludedInventoryCountProductsReq, SearchExcludedInventoryCountProductsRes>;
    /**
     * @generated from protobuf rpc: SearchOrders(rd.search.service.SearchOrdersReq) returns (rd.search.service.SearchOrdersRes);
     */
    searchOrders(input: SearchOrdersReq, options?: RpcOptions): UnaryCall<SearchOrdersReq, SearchOrdersRes>;
    /**
     * @generated from protobuf rpc: SearchOrderShipments(rd.search.service.SearchOrderShipmentsReq) returns (rd.search.service.SearchOrderShipmentsRes);
     */
    searchOrderShipments(input: SearchOrderShipmentsReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsReq, SearchOrderShipmentsRes>;
    /**
     * @generated from protobuf rpc: SearchProductsV2(rd.search.service.SearchProductsV2Req) returns (rd.search.service.SearchProductsV2Res);
     */
    searchProductsV2(input: SearchProductsV2Req, options?: RpcOptions): UnaryCall<SearchProductsV2Req, SearchProductsV2Res>;
    /**
     * @generated from protobuf rpc: SearchSalesV2(rd.search.service.SearchSalesV2Req) returns (rd.search.service.SearchSalesV2Res);
     */
    searchSalesV2(input: SearchSalesV2Req, options?: RpcOptions): UnaryCall<SearchSalesV2Req, SearchSalesV2Res>;
    /**
     * @generated from protobuf rpc: SearchReturns(rd.search.service.SearchReturnsReq) returns (rd.search.service.SearchReturnsRes);
     */
    searchReturns(input: SearchReturnsReq, options?: RpcOptions): UnaryCall<SearchReturnsReq, SearchReturnsRes>;
    /**
     * @generated from protobuf rpc: SearchOutstandingSales(rd.search.service.SearchOutstandingSalesReq) returns (rd.search.service.SearchOutstandingSalesRes);
     */
    searchOutstandingSales(input: SearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<SearchOutstandingSalesReq, SearchOutstandingSalesRes>;
    /**
     * @generated from protobuf rpc: SearchTintColors(rd.search.service.SearchTintColorsReq) returns (rd.search.service.SearchTintColorsRes);
     */
    searchTintColors(input: SearchTintColorsReq, options?: RpcOptions): UnaryCall<SearchTintColorsReq, SearchTintColorsRes>;
    /**
     * @generated from protobuf rpc: SearchTransfers(rd.search.service.SearchTransfersReq) returns (rd.search.service.SearchTransfersRes);
     */
    searchTransfers(input: SearchTransfersReq, options?: RpcOptions): UnaryCall<SearchTransfersReq, SearchTransfersRes>;
    /**
     * @generated from protobuf rpc: SearchVendors(rd.search.service.SearchVendorsReq) returns (rd.search.service.SearchVendorsRes);
     */
    searchVendors(input: SearchVendorsReq, options?: RpcOptions): UnaryCall<SearchVendorsReq, SearchVendorsRes>;
    /**
     * @generated from protobuf rpc: SearchCashTransactions(rd.search.service.SearchCashTransactionsReq) returns (rd.search.service.SearchCashTransactionsRes);
     */
    searchCashTransactions(input: SearchCashTransactionsReq, options?: RpcOptions): UnaryCall<SearchCashTransactionsReq, SearchCashTransactionsRes>;
    /**
     * @generated from protobuf rpc: SearchFinancialTransactions(rd.search.service.SearchFinancialTransactionsReq) returns (rd.search.service.SearchFinancialTransactionsRes);
     */
    searchFinancialTransactions(input: SearchFinancialTransactionsReq, options?: RpcOptions): UnaryCall<SearchFinancialTransactionsReq, SearchFinancialTransactionsRes>;
    /**
     * @generated from protobuf rpc: UpdateRecord(rd.search.service.UpdateRecordReq) returns (rd.search.service.UpdateRecordRes);
     */
    updateRecord(input: UpdateRecordReq, options?: RpcOptions): UnaryCall<UpdateRecordReq, UpdateRecordRes>;
    /**
     * @generated from protobuf rpc: Reload(rd.search.service.ReloadReq) returns (rd.search.service.ReloadRes);
     */
    reload(input: ReloadReq, options?: RpcOptions): UnaryCall<ReloadReq, ReloadRes>;
    /**
     * @generated from protobuf rpc: SearchOrderShipmentsForReconciliation(rd.search.service.SearchOrderShipmentsForReconciliationReq) returns (rd.search.service.SearchOrderShipmentsForReconciliationRes);
     */
    searchOrderShipmentsForReconciliation(input: SearchOrderShipmentsForReconciliationReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsForReconciliationReq, SearchOrderShipmentsForReconciliationRes>;
    /**
     * @generated from protobuf rpc: SearchInventoryChanges(rd.search.service.SearchInventoryChangesReq) returns (rd.search.service.SearchInventoryChangesRes);
     */
    searchInventoryChanges(input: SearchInventoryChangesReq, options?: RpcOptions): UnaryCall<SearchInventoryChangesReq, SearchInventoryChangesRes>;
    /**
     * @generated from protobuf rpc: SearchCustomPriceRules(rd.search.service.SearchCustomPriceRulesReq) returns (rd.search.service.SearchCustomPriceRulesRes);
     */
    searchCustomPriceRules(input: SearchCustomPriceRulesReq, options?: RpcOptions): UnaryCall<SearchCustomPriceRulesReq, SearchCustomPriceRulesRes>;
    /**
     * @generated from protobuf rpc: SearchPriceTierRules(rd.search.service.SearchPriceTierRulesReq) returns (rd.search.service.SearchPriceTierRulesRes);
     */
    searchPriceTierRules(input: SearchPriceTierRulesReq, options?: RpcOptions): UnaryCall<SearchPriceTierRulesReq, SearchPriceTierRulesRes>;
}
/**
 * @generated from protobuf service rd.search.service.SearchService
 */
export class SearchServiceClient implements ISearchServiceClient, ServiceInfo {
    typeName = SearchService.typeName;
    methods = SearchService.methods;
    options = SearchService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: SearchBillPayments(rd.search.service.SearchBillPaymentsReq) returns (rd.search.service.SearchBillPaymentsRes);
     */
    searchBillPayments(input: SearchBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchBillPaymentsReq, SearchBillPaymentsRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchBillPaymentsReq, SearchBillPaymentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchRefundedBillPayments(rd.search.service.SearchRefundedBillPaymentsReq) returns (rd.search.service.SearchRefundedBillPaymentsRes);
     */
    searchRefundedBillPayments(input: SearchRefundedBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchRefundedBillPaymentsReq, SearchRefundedBillPaymentsRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchRefundedBillPaymentsReq, SearchRefundedBillPaymentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchCustomersV2(rd.search.service.SearchCustomersV2Req) returns (rd.search.service.SearchCustomersV2Res);
     */
    searchCustomersV2(input: SearchCustomersV2Req, options?: RpcOptions): UnaryCall<SearchCustomersV2Req, SearchCustomersV2Res> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchCustomersV2Req, SearchCustomersV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchExternalTransactions(rd.search.service.SearchExternalTransactionsReq) returns (rd.search.service.SearchExternalTransactionsRes);
     */
    searchExternalTransactions(input: SearchExternalTransactionsReq, options?: RpcOptions): UnaryCall<SearchExternalTransactionsReq, SearchExternalTransactionsRes> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchExternalTransactionsReq, SearchExternalTransactionsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchInventoryCountProducts(rd.search.service.SearchInventoryCountProductsReq) returns (rd.search.service.SearchInventoryCountProductsRes);
     */
    searchInventoryCountProducts(input: SearchInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchInventoryCountProductsReq, SearchInventoryCountProductsRes> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchInventoryCountProductsReq, SearchInventoryCountProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchExcludedInventoryCountProducts(rd.search.service.SearchExcludedInventoryCountProductsReq) returns (rd.search.service.SearchExcludedInventoryCountProductsRes);
     */
    searchExcludedInventoryCountProducts(input: SearchExcludedInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchExcludedInventoryCountProductsReq, SearchExcludedInventoryCountProductsRes> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchExcludedInventoryCountProductsReq, SearchExcludedInventoryCountProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOrders(rd.search.service.SearchOrdersReq) returns (rd.search.service.SearchOrdersRes);
     */
    searchOrders(input: SearchOrdersReq, options?: RpcOptions): UnaryCall<SearchOrdersReq, SearchOrdersRes> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOrdersReq, SearchOrdersRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOrderShipments(rd.search.service.SearchOrderShipmentsReq) returns (rd.search.service.SearchOrderShipmentsRes);
     */
    searchOrderShipments(input: SearchOrderShipmentsReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsReq, SearchOrderShipmentsRes> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOrderShipmentsReq, SearchOrderShipmentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchProductsV2(rd.search.service.SearchProductsV2Req) returns (rd.search.service.SearchProductsV2Res);
     */
    searchProductsV2(input: SearchProductsV2Req, options?: RpcOptions): UnaryCall<SearchProductsV2Req, SearchProductsV2Res> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchProductsV2Req, SearchProductsV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchSalesV2(rd.search.service.SearchSalesV2Req) returns (rd.search.service.SearchSalesV2Res);
     */
    searchSalesV2(input: SearchSalesV2Req, options?: RpcOptions): UnaryCall<SearchSalesV2Req, SearchSalesV2Res> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchSalesV2Req, SearchSalesV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchReturns(rd.search.service.SearchReturnsReq) returns (rd.search.service.SearchReturnsRes);
     */
    searchReturns(input: SearchReturnsReq, options?: RpcOptions): UnaryCall<SearchReturnsReq, SearchReturnsRes> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchReturnsReq, SearchReturnsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOutstandingSales(rd.search.service.SearchOutstandingSalesReq) returns (rd.search.service.SearchOutstandingSalesRes);
     */
    searchOutstandingSales(input: SearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<SearchOutstandingSalesReq, SearchOutstandingSalesRes> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOutstandingSalesReq, SearchOutstandingSalesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchTintColors(rd.search.service.SearchTintColorsReq) returns (rd.search.service.SearchTintColorsRes);
     */
    searchTintColors(input: SearchTintColorsReq, options?: RpcOptions): UnaryCall<SearchTintColorsReq, SearchTintColorsRes> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchTintColorsReq, SearchTintColorsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchTransfers(rd.search.service.SearchTransfersReq) returns (rd.search.service.SearchTransfersRes);
     */
    searchTransfers(input: SearchTransfersReq, options?: RpcOptions): UnaryCall<SearchTransfersReq, SearchTransfersRes> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchTransfersReq, SearchTransfersRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchVendors(rd.search.service.SearchVendorsReq) returns (rd.search.service.SearchVendorsRes);
     */
    searchVendors(input: SearchVendorsReq, options?: RpcOptions): UnaryCall<SearchVendorsReq, SearchVendorsRes> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchVendorsReq, SearchVendorsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchCashTransactions(rd.search.service.SearchCashTransactionsReq) returns (rd.search.service.SearchCashTransactionsRes);
     */
    searchCashTransactions(input: SearchCashTransactionsReq, options?: RpcOptions): UnaryCall<SearchCashTransactionsReq, SearchCashTransactionsRes> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchCashTransactionsReq, SearchCashTransactionsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchFinancialTransactions(rd.search.service.SearchFinancialTransactionsReq) returns (rd.search.service.SearchFinancialTransactionsRes);
     */
    searchFinancialTransactions(input: SearchFinancialTransactionsReq, options?: RpcOptions): UnaryCall<SearchFinancialTransactionsReq, SearchFinancialTransactionsRes> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchFinancialTransactionsReq, SearchFinancialTransactionsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRecord(rd.search.service.UpdateRecordReq) returns (rd.search.service.UpdateRecordRes);
     */
    updateRecord(input: UpdateRecordReq, options?: RpcOptions): UnaryCall<UpdateRecordReq, UpdateRecordRes> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateRecordReq, UpdateRecordRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Reload(rd.search.service.ReloadReq) returns (rd.search.service.ReloadRes);
     */
    reload(input: ReloadReq, options?: RpcOptions): UnaryCall<ReloadReq, ReloadRes> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReloadReq, ReloadRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOrderShipmentsForReconciliation(rd.search.service.SearchOrderShipmentsForReconciliationReq) returns (rd.search.service.SearchOrderShipmentsForReconciliationRes);
     */
    searchOrderShipmentsForReconciliation(input: SearchOrderShipmentsForReconciliationReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsForReconciliationReq, SearchOrderShipmentsForReconciliationRes> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOrderShipmentsForReconciliationReq, SearchOrderShipmentsForReconciliationRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchInventoryChanges(rd.search.service.SearchInventoryChangesReq) returns (rd.search.service.SearchInventoryChangesRes);
     */
    searchInventoryChanges(input: SearchInventoryChangesReq, options?: RpcOptions): UnaryCall<SearchInventoryChangesReq, SearchInventoryChangesRes> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchInventoryChangesReq, SearchInventoryChangesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchCustomPriceRules(rd.search.service.SearchCustomPriceRulesReq) returns (rd.search.service.SearchCustomPriceRulesRes);
     */
    searchCustomPriceRules(input: SearchCustomPriceRulesReq, options?: RpcOptions): UnaryCall<SearchCustomPriceRulesReq, SearchCustomPriceRulesRes> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchCustomPriceRulesReq, SearchCustomPriceRulesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchPriceTierRules(rd.search.service.SearchPriceTierRulesReq) returns (rd.search.service.SearchPriceTierRulesRes);
     */
    searchPriceTierRules(input: SearchPriceTierRulesReq, options?: RpcOptions): UnaryCall<SearchPriceTierRulesReq, SearchPriceTierRulesRes> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchPriceTierRulesReq, SearchPriceTierRulesRes>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service rd.search.service.BulkService
 */
export interface IBulkServiceClient {
    /**
     * @generated from protobuf rpc: BulkExportCustomers(rd.search.service.BulkExportCustomersReq) returns (rd.search.service.BulkExportCustomersRes);
     */
    bulkExportCustomers(input: BulkExportCustomersReq, options?: RpcOptions): UnaryCall<BulkExportCustomersReq, BulkExportCustomersRes>;
    /**
     * @generated from protobuf rpc: BulkExportProducts(rd.search.service.BulkExportProductsReq) returns (rd.search.service.BulkExportProductsRes);
     */
    bulkExportProducts(input: BulkExportProductsReq, options?: RpcOptions): UnaryCall<BulkExportProductsReq, BulkExportProductsRes>;
    /**
     * @generated from protobuf rpc: BulkExportProductLabels(rd.search.service.BulkExportProductLabelsReq) returns (rd.search.service.BulkExportProductLabelsRes);
     */
    bulkExportProductLabels(input: BulkExportProductLabelsReq, options?: RpcOptions): UnaryCall<BulkExportProductLabelsReq, BulkExportProductLabelsRes>;
    /**
     * @generated from protobuf rpc: BulkExportVendors(rd.search.service.BulkExportVendorsReq) returns (rd.search.service.BulkExportVendorsRes);
     */
    bulkExportVendors(input: BulkExportVendorsReq, options?: RpcOptions): UnaryCall<BulkExportVendorsReq, BulkExportVendorsRes>;
}
/**
 * @generated from protobuf service rd.search.service.BulkService
 */
export class BulkServiceClient implements IBulkServiceClient, ServiceInfo {
    typeName = BulkService.typeName;
    methods = BulkService.methods;
    options = BulkService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: BulkExportCustomers(rd.search.service.BulkExportCustomersReq) returns (rd.search.service.BulkExportCustomersRes);
     */
    bulkExportCustomers(input: BulkExportCustomersReq, options?: RpcOptions): UnaryCall<BulkExportCustomersReq, BulkExportCustomersRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportCustomersReq, BulkExportCustomersRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkExportProducts(rd.search.service.BulkExportProductsReq) returns (rd.search.service.BulkExportProductsRes);
     */
    bulkExportProducts(input: BulkExportProductsReq, options?: RpcOptions): UnaryCall<BulkExportProductsReq, BulkExportProductsRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportProductsReq, BulkExportProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkExportProductLabels(rd.search.service.BulkExportProductLabelsReq) returns (rd.search.service.BulkExportProductLabelsRes);
     */
    bulkExportProductLabels(input: BulkExportProductLabelsReq, options?: RpcOptions): UnaryCall<BulkExportProductLabelsReq, BulkExportProductLabelsRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportProductLabelsReq, BulkExportProductLabelsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkExportVendors(rd.search.service.BulkExportVendorsReq) returns (rd.search.service.BulkExportVendorsRes);
     */
    bulkExportVendors(input: BulkExportVendorsReq, options?: RpcOptions): UnaryCall<BulkExportVendorsReq, BulkExportVendorsRes> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportVendorsReq, BulkExportVendorsRes>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service rd.search.service.SearchalyticsService
 */
export interface ISearchalyticsServiceClient {
    /**
     * @generated from protobuf rpc: GetSalesByDay(rd.api.analytics.GetSalesByDayReq) returns (rd.api.analytics.GetSalesByDayRes);
     */
    getSalesByDay(input: GetSalesByDayReq, options?: RpcOptions): UnaryCall<GetSalesByDayReq, GetSalesByDayRes>;
    /**
     * @generated from protobuf rpc: GetSalesByDayPDF(rd.api.analytics.GetSalesByDayPDFReq) returns (rd.api.analytics.GetSalesByDayPDFRes);
     */
    getSalesByDayPDF(input: GetSalesByDayPDFReq, options?: RpcOptions): UnaryCall<GetSalesByDayPDFReq, GetSalesByDayPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesByDayCSV(rd.api.analytics.GetSalesByDayCSVReq) returns (rd.api.analytics.GetSalesByDayCSVRes);
     */
    getSalesByDayCSV(input: GetSalesByDayCSVReq, options?: RpcOptions): UnaryCall<GetSalesByDayCSVReq, GetSalesByDayCSVRes>;
    /**
     * Cash Register Report
     *
     * @generated from protobuf rpc: GetCashRegister(rd.search.service.GetCashRegisterReq) returns (rd.search.service.GetCashRegisterRes);
     */
    getCashRegister(input: GetCashRegisterReq, options?: RpcOptions): UnaryCall<GetCashRegisterReq, GetCashRegisterRes>;
    /**
     * @generated from protobuf rpc: GetCashRegisterPDF(rd.search.service.GetCashRegisterPDFReq) returns (rd.search.service.GetCashRegisterPDFRes);
     */
    getCashRegisterPDF(input: GetCashRegisterPDFReq, options?: RpcOptions): UnaryCall<GetCashRegisterPDFReq, GetCashRegisterPDFRes>;
    /**
     * @generated from protobuf rpc: GetCashRegisterCSV(rd.search.service.GetCashRegisterCSVReq) returns (rd.search.service.GetCashRegisterCSVRes);
     */
    getCashRegisterCSV(input: GetCashRegisterCSVReq, options?: RpcOptions): UnaryCall<GetCashRegisterCSVReq, GetCashRegisterCSVRes>;
    /**
     * @generated from protobuf rpc: GetSales(rd.search.service.GetSalesReq) returns (rd.search.service.GetSalesRes);
     */
    getSales(input: GetSalesReq, options?: RpcOptions): UnaryCall<GetSalesReq, GetSalesRes>;
    /**
     * @generated from protobuf rpc: GetSalesPDF(rd.search.service.GetSalesPDFReq) returns (rd.search.service.GetSalesPDFRes);
     */
    getSalesPDF(input: GetSalesPDFReq, options?: RpcOptions): UnaryCall<GetSalesPDFReq, GetSalesPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesCSV(rd.search.service.GetSalesCSVReq) returns (rd.search.service.GetSalesCSVRes);
     */
    getSalesCSV(input: GetSalesCSVReq, options?: RpcOptions): UnaryCall<GetSalesCSVReq, GetSalesCSVRes>;
    /**
     * Payments
     *
     * @generated from protobuf rpc: GetPayments(rd.search.service.GetPaymentsReq) returns (rd.search.service.GetPaymentsRes);
     */
    getPayments(input: GetPaymentsReq, options?: RpcOptions): UnaryCall<GetPaymentsReq, GetPaymentsRes>;
    /**
     * @generated from protobuf rpc: GetPaymentsPDF(rd.search.service.GetPaymentsPDFReq) returns (rd.search.service.GetPaymentsPDFRes);
     */
    getPaymentsPDF(input: GetPaymentsPDFReq, options?: RpcOptions): UnaryCall<GetPaymentsPDFReq, GetPaymentsPDFRes>;
    /**
     * @generated from protobuf rpc: GetPaymentsCSV(rd.search.service.GetPaymentsCSVReq) returns (rd.search.service.GetPaymentsCSVRes);
     */
    getPaymentsCSV(input: GetPaymentsCSVReq, options?: RpcOptions): UnaryCall<GetPaymentsCSVReq, GetPaymentsCSVRes>;
    /**
     * Sold Products
     *
     * @generated from protobuf rpc: GetSoldProducts(rd.search.service.GetSoldProductsReq) returns (rd.search.service.GetSoldProductsRes);
     */
    getSoldProducts(input: GetSoldProductsReq, options?: RpcOptions): UnaryCall<GetSoldProductsReq, GetSoldProductsRes>;
    /**
     * @generated from protobuf rpc: GetSoldProductsPDF(rd.search.service.GetSoldProductsPDFReq) returns (rd.search.service.GetSoldProductsPDFRes);
     */
    getSoldProductsPDF(input: GetSoldProductsPDFReq, options?: RpcOptions): UnaryCall<GetSoldProductsPDFReq, GetSoldProductsPDFRes>;
    /**
     * @generated from protobuf rpc: GetSoldProductsCSV(rd.search.service.GetSoldProductsCSVReq) returns (rd.search.service.GetSoldProductsCSVRes);
     */
    getSoldProductsCSV(input: GetSoldProductsCSVReq, options?: RpcOptions): UnaryCall<GetSoldProductsCSVReq, GetSoldProductsCSVRes>;
    /**
     * Sales by Customer
     *
     * @generated from protobuf rpc: GetSalesByCustomer(rd.search.service.GetSalesByCustomerReq) returns (rd.search.service.GetSalesByCustomerRes);
     */
    getSalesByCustomer(input: GetSalesByCustomerReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerReq, GetSalesByCustomerRes>;
    /**
     * @generated from protobuf rpc: GetSalesByCustomerPDF(rd.search.service.GetSalesByCustomerPDFReq) returns (rd.search.service.GetSalesByCustomerPDFRes);
     */
    getSalesByCustomerPDF(input: GetSalesByCustomerPDFReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerPDFReq, GetSalesByCustomerPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesByCustomerCSV(rd.search.service.GetSalesByCustomerCSVReq) returns (rd.search.service.GetSalesByCustomerCSVRes);
     */
    getSalesByCustomerCSV(input: GetSalesByCustomerCSVReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerCSVReq, GetSalesByCustomerCSVRes>;
    /**
     * Sales by Product
     *
     * @generated from protobuf rpc: GetSalesByProduct(rd.search.service.GetSalesByProductReq) returns (rd.search.service.GetSalesByProductRes);
     */
    getSalesByProduct(input: GetSalesByProductReq, options?: RpcOptions): UnaryCall<GetSalesByProductReq, GetSalesByProductRes>;
    /**
     * @generated from protobuf rpc: GetSalesByProductPDF(rd.search.service.GetSalesByProductPDFReq) returns (rd.search.service.GetSalesByProductPDFRes);
     */
    getSalesByProductPDF(input: GetSalesByProductPDFReq, options?: RpcOptions): UnaryCall<GetSalesByProductPDFReq, GetSalesByProductPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesByProductCSV(rd.search.service.GetSalesByProductCSVReq) returns (rd.search.service.GetSalesByProductCSVRes);
     */
    getSalesByProductCSV(input: GetSalesByProductCSVReq, options?: RpcOptions): UnaryCall<GetSalesByProductCSVReq, GetSalesByProductCSVRes>;
    /**
     * Customer Aging
     *
     * @generated from protobuf rpc: GetCustomerAging(rd.api.analytics.GetCustomerAgingReq) returns (rd.api.analytics.GetCustomerAgingRes);
     */
    getCustomerAging(input: GetCustomerAgingReq, options?: RpcOptions): UnaryCall<GetCustomerAgingReq, GetCustomerAgingRes>;
    /**
     * @generated from protobuf rpc: GetCustomerAgingPDF(rd.api.analytics.GetCustomerAgingPDFReq) returns (rd.api.analytics.GetCustomerAgingPDFRes);
     */
    getCustomerAgingPDF(input: GetCustomerAgingPDFReq, options?: RpcOptions): UnaryCall<GetCustomerAgingPDFReq, GetCustomerAgingPDFRes>;
    /**
     * @generated from protobuf rpc: GetCustomerAgingCSV(rd.api.analytics.GetCustomerAgingCSVReq) returns (rd.api.analytics.GetCustomerAgingCSVRes);
     */
    getCustomerAgingCSV(input: GetCustomerAgingCSVReq, options?: RpcOptions): UnaryCall<GetCustomerAgingCSVReq, GetCustomerAgingCSVRes>;
    /**
     * Profit and Loss
     *
     * @generated from protobuf rpc: GetProfitAndLoss(rd.search.service.GetProfitAndLossReq) returns (rd.search.service.GetProfitAndLossRes);
     */
    getProfitAndLoss(input: GetProfitAndLossReq, options?: RpcOptions): UnaryCall<GetProfitAndLossReq, GetProfitAndLossRes>;
    /**
     * @generated from protobuf rpc: GetProfitAndLossPDF(rd.search.service.GetProfitAndLossPDFReq) returns (rd.search.service.GetProfitAndLossPDFRes);
     */
    getProfitAndLossPDF(input: GetProfitAndLossPDFReq, options?: RpcOptions): UnaryCall<GetProfitAndLossPDFReq, GetProfitAndLossPDFRes>;
    /**
     * New Accounts Opened
     *
     * @generated from protobuf rpc: GetNewAccountsOpened(rd.search.service.GetNewAccountsOpenedReq) returns (rd.search.service.GetNewAccountsOpenedRes);
     */
    getNewAccountsOpened(input: GetNewAccountsOpenedReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedReq, GetNewAccountsOpenedRes>;
    /**
     * @generated from protobuf rpc: GetNewAccountsOpenedPDF(rd.search.service.GetNewAccountsOpenedPDFReq) returns (rd.search.service.GetNewAccountsOpenedPDFRes);
     */
    getNewAccountsOpenedPDF(input: GetNewAccountsOpenedPDFReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedPDFReq, GetNewAccountsOpenedPDFRes>;
    /**
     * @generated from protobuf rpc: GetNewAccountsOpenedCSV(rd.search.service.GetNewAccountsOpenedCSVReq) returns (rd.search.service.GetNewAccountsOpenedCSVRes);
     */
    getNewAccountsOpenedCSV(input: GetNewAccountsOpenedCSVReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedCSVReq, GetNewAccountsOpenedCSVRes>;
    /**
     * Aging Detail
     *
     * @generated from protobuf rpc: GetAgingDetail(rd.search.service.GetAgingDetailReq) returns (rd.search.service.GetAgingDetailRes);
     */
    getAgingDetail(input: GetAgingDetailReq, options?: RpcOptions): UnaryCall<GetAgingDetailReq, GetAgingDetailRes>;
    /**
     * @generated from protobuf rpc: GetAgingDetailPDF(rd.search.service.GetAgingDetailPDFReq) returns (rd.search.service.GetAgingDetailPDFRes);
     */
    getAgingDetailPDF(input: GetAgingDetailPDFReq, options?: RpcOptions): UnaryCall<GetAgingDetailPDFReq, GetAgingDetailPDFRes>;
    /**
     * @generated from protobuf rpc: GetAgingDetailCSV(rd.search.service.GetAgingDetailCSVReq) returns (rd.search.service.GetAgingDetailCSVRes);
     */
    getAgingDetailCSV(input: GetAgingDetailCSVReq, options?: RpcOptions): UnaryCall<GetAgingDetailCSVReq, GetAgingDetailCSVRes>;
}
/**
 * @generated from protobuf service rd.search.service.SearchalyticsService
 */
export class SearchalyticsServiceClient implements ISearchalyticsServiceClient, ServiceInfo {
    typeName = SearchalyticsService.typeName;
    methods = SearchalyticsService.methods;
    options = SearchalyticsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetSalesByDay(rd.api.analytics.GetSalesByDayReq) returns (rd.api.analytics.GetSalesByDayRes);
     */
    getSalesByDay(input: GetSalesByDayReq, options?: RpcOptions): UnaryCall<GetSalesByDayReq, GetSalesByDayRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByDayReq, GetSalesByDayRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByDayPDF(rd.api.analytics.GetSalesByDayPDFReq) returns (rd.api.analytics.GetSalesByDayPDFRes);
     */
    getSalesByDayPDF(input: GetSalesByDayPDFReq, options?: RpcOptions): UnaryCall<GetSalesByDayPDFReq, GetSalesByDayPDFRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByDayPDFReq, GetSalesByDayPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByDayCSV(rd.api.analytics.GetSalesByDayCSVReq) returns (rd.api.analytics.GetSalesByDayCSVRes);
     */
    getSalesByDayCSV(input: GetSalesByDayCSVReq, options?: RpcOptions): UnaryCall<GetSalesByDayCSVReq, GetSalesByDayCSVRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByDayCSVReq, GetSalesByDayCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Cash Register Report
     *
     * @generated from protobuf rpc: GetCashRegister(rd.search.service.GetCashRegisterReq) returns (rd.search.service.GetCashRegisterRes);
     */
    getCashRegister(input: GetCashRegisterReq, options?: RpcOptions): UnaryCall<GetCashRegisterReq, GetCashRegisterRes> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCashRegisterReq, GetCashRegisterRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCashRegisterPDF(rd.search.service.GetCashRegisterPDFReq) returns (rd.search.service.GetCashRegisterPDFRes);
     */
    getCashRegisterPDF(input: GetCashRegisterPDFReq, options?: RpcOptions): UnaryCall<GetCashRegisterPDFReq, GetCashRegisterPDFRes> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCashRegisterPDFReq, GetCashRegisterPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCashRegisterCSV(rd.search.service.GetCashRegisterCSVReq) returns (rd.search.service.GetCashRegisterCSVRes);
     */
    getCashRegisterCSV(input: GetCashRegisterCSVReq, options?: RpcOptions): UnaryCall<GetCashRegisterCSVReq, GetCashRegisterCSVRes> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCashRegisterCSVReq, GetCashRegisterCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSales(rd.search.service.GetSalesReq) returns (rd.search.service.GetSalesRes);
     */
    getSales(input: GetSalesReq, options?: RpcOptions): UnaryCall<GetSalesReq, GetSalesRes> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesReq, GetSalesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesPDF(rd.search.service.GetSalesPDFReq) returns (rd.search.service.GetSalesPDFRes);
     */
    getSalesPDF(input: GetSalesPDFReq, options?: RpcOptions): UnaryCall<GetSalesPDFReq, GetSalesPDFRes> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesPDFReq, GetSalesPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesCSV(rd.search.service.GetSalesCSVReq) returns (rd.search.service.GetSalesCSVRes);
     */
    getSalesCSV(input: GetSalesCSVReq, options?: RpcOptions): UnaryCall<GetSalesCSVReq, GetSalesCSVRes> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesCSVReq, GetSalesCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Payments
     *
     * @generated from protobuf rpc: GetPayments(rd.search.service.GetPaymentsReq) returns (rd.search.service.GetPaymentsRes);
     */
    getPayments(input: GetPaymentsReq, options?: RpcOptions): UnaryCall<GetPaymentsReq, GetPaymentsRes> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPaymentsReq, GetPaymentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPaymentsPDF(rd.search.service.GetPaymentsPDFReq) returns (rd.search.service.GetPaymentsPDFRes);
     */
    getPaymentsPDF(input: GetPaymentsPDFReq, options?: RpcOptions): UnaryCall<GetPaymentsPDFReq, GetPaymentsPDFRes> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPaymentsPDFReq, GetPaymentsPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPaymentsCSV(rd.search.service.GetPaymentsCSVReq) returns (rd.search.service.GetPaymentsCSVRes);
     */
    getPaymentsCSV(input: GetPaymentsCSVReq, options?: RpcOptions): UnaryCall<GetPaymentsCSVReq, GetPaymentsCSVRes> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPaymentsCSVReq, GetPaymentsCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Sold Products
     *
     * @generated from protobuf rpc: GetSoldProducts(rd.search.service.GetSoldProductsReq) returns (rd.search.service.GetSoldProductsRes);
     */
    getSoldProducts(input: GetSoldProductsReq, options?: RpcOptions): UnaryCall<GetSoldProductsReq, GetSoldProductsRes> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSoldProductsReq, GetSoldProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSoldProductsPDF(rd.search.service.GetSoldProductsPDFReq) returns (rd.search.service.GetSoldProductsPDFRes);
     */
    getSoldProductsPDF(input: GetSoldProductsPDFReq, options?: RpcOptions): UnaryCall<GetSoldProductsPDFReq, GetSoldProductsPDFRes> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSoldProductsPDFReq, GetSoldProductsPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSoldProductsCSV(rd.search.service.GetSoldProductsCSVReq) returns (rd.search.service.GetSoldProductsCSVRes);
     */
    getSoldProductsCSV(input: GetSoldProductsCSVReq, options?: RpcOptions): UnaryCall<GetSoldProductsCSVReq, GetSoldProductsCSVRes> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSoldProductsCSVReq, GetSoldProductsCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Sales by Customer
     *
     * @generated from protobuf rpc: GetSalesByCustomer(rd.search.service.GetSalesByCustomerReq) returns (rd.search.service.GetSalesByCustomerRes);
     */
    getSalesByCustomer(input: GetSalesByCustomerReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerReq, GetSalesByCustomerRes> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByCustomerReq, GetSalesByCustomerRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByCustomerPDF(rd.search.service.GetSalesByCustomerPDFReq) returns (rd.search.service.GetSalesByCustomerPDFRes);
     */
    getSalesByCustomerPDF(input: GetSalesByCustomerPDFReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerPDFReq, GetSalesByCustomerPDFRes> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByCustomerPDFReq, GetSalesByCustomerPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByCustomerCSV(rd.search.service.GetSalesByCustomerCSVReq) returns (rd.search.service.GetSalesByCustomerCSVRes);
     */
    getSalesByCustomerCSV(input: GetSalesByCustomerCSVReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerCSVReq, GetSalesByCustomerCSVRes> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByCustomerCSVReq, GetSalesByCustomerCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Sales by Product
     *
     * @generated from protobuf rpc: GetSalesByProduct(rd.search.service.GetSalesByProductReq) returns (rd.search.service.GetSalesByProductRes);
     */
    getSalesByProduct(input: GetSalesByProductReq, options?: RpcOptions): UnaryCall<GetSalesByProductReq, GetSalesByProductRes> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByProductReq, GetSalesByProductRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByProductPDF(rd.search.service.GetSalesByProductPDFReq) returns (rd.search.service.GetSalesByProductPDFRes);
     */
    getSalesByProductPDF(input: GetSalesByProductPDFReq, options?: RpcOptions): UnaryCall<GetSalesByProductPDFReq, GetSalesByProductPDFRes> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByProductPDFReq, GetSalesByProductPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByProductCSV(rd.search.service.GetSalesByProductCSVReq) returns (rd.search.service.GetSalesByProductCSVRes);
     */
    getSalesByProductCSV(input: GetSalesByProductCSVReq, options?: RpcOptions): UnaryCall<GetSalesByProductCSVReq, GetSalesByProductCSVRes> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByProductCSVReq, GetSalesByProductCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Customer Aging
     *
     * @generated from protobuf rpc: GetCustomerAging(rd.api.analytics.GetCustomerAgingReq) returns (rd.api.analytics.GetCustomerAgingRes);
     */
    getCustomerAging(input: GetCustomerAgingReq, options?: RpcOptions): UnaryCall<GetCustomerAgingReq, GetCustomerAgingRes> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCustomerAgingReq, GetCustomerAgingRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCustomerAgingPDF(rd.api.analytics.GetCustomerAgingPDFReq) returns (rd.api.analytics.GetCustomerAgingPDFRes);
     */
    getCustomerAgingPDF(input: GetCustomerAgingPDFReq, options?: RpcOptions): UnaryCall<GetCustomerAgingPDFReq, GetCustomerAgingPDFRes> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCustomerAgingPDFReq, GetCustomerAgingPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCustomerAgingCSV(rd.api.analytics.GetCustomerAgingCSVReq) returns (rd.api.analytics.GetCustomerAgingCSVRes);
     */
    getCustomerAgingCSV(input: GetCustomerAgingCSVReq, options?: RpcOptions): UnaryCall<GetCustomerAgingCSVReq, GetCustomerAgingCSVRes> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCustomerAgingCSVReq, GetCustomerAgingCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Profit and Loss
     *
     * @generated from protobuf rpc: GetProfitAndLoss(rd.search.service.GetProfitAndLossReq) returns (rd.search.service.GetProfitAndLossRes);
     */
    getProfitAndLoss(input: GetProfitAndLossReq, options?: RpcOptions): UnaryCall<GetProfitAndLossReq, GetProfitAndLossRes> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProfitAndLossReq, GetProfitAndLossRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProfitAndLossPDF(rd.search.service.GetProfitAndLossPDFReq) returns (rd.search.service.GetProfitAndLossPDFRes);
     */
    getProfitAndLossPDF(input: GetProfitAndLossPDFReq, options?: RpcOptions): UnaryCall<GetProfitAndLossPDFReq, GetProfitAndLossPDFRes> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProfitAndLossPDFReq, GetProfitAndLossPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * New Accounts Opened
     *
     * @generated from protobuf rpc: GetNewAccountsOpened(rd.search.service.GetNewAccountsOpenedReq) returns (rd.search.service.GetNewAccountsOpenedRes);
     */
    getNewAccountsOpened(input: GetNewAccountsOpenedReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedReq, GetNewAccountsOpenedRes> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetNewAccountsOpenedReq, GetNewAccountsOpenedRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetNewAccountsOpenedPDF(rd.search.service.GetNewAccountsOpenedPDFReq) returns (rd.search.service.GetNewAccountsOpenedPDFRes);
     */
    getNewAccountsOpenedPDF(input: GetNewAccountsOpenedPDFReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedPDFReq, GetNewAccountsOpenedPDFRes> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetNewAccountsOpenedPDFReq, GetNewAccountsOpenedPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetNewAccountsOpenedCSV(rd.search.service.GetNewAccountsOpenedCSVReq) returns (rd.search.service.GetNewAccountsOpenedCSVRes);
     */
    getNewAccountsOpenedCSV(input: GetNewAccountsOpenedCSVReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedCSVReq, GetNewAccountsOpenedCSVRes> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetNewAccountsOpenedCSVReq, GetNewAccountsOpenedCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Aging Detail
     *
     * @generated from protobuf rpc: GetAgingDetail(rd.search.service.GetAgingDetailReq) returns (rd.search.service.GetAgingDetailRes);
     */
    getAgingDetail(input: GetAgingDetailReq, options?: RpcOptions): UnaryCall<GetAgingDetailReq, GetAgingDetailRes> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgingDetailReq, GetAgingDetailRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAgingDetailPDF(rd.search.service.GetAgingDetailPDFReq) returns (rd.search.service.GetAgingDetailPDFRes);
     */
    getAgingDetailPDF(input: GetAgingDetailPDFReq, options?: RpcOptions): UnaryCall<GetAgingDetailPDFReq, GetAgingDetailPDFRes> {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgingDetailPDFReq, GetAgingDetailPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAgingDetailCSV(rd.search.service.GetAgingDetailCSVReq) returns (rd.search.service.GetAgingDetailCSVRes);
     */
    getAgingDetailCSV(input: GetAgingDetailCSVReq, options?: RpcOptions): UnaryCall<GetAgingDetailCSVReq, GetAgingDetailCSVRes> {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgingDetailCSVReq, GetAgingDetailCSVRes>("unary", this._transport, method, opt, input);
    }
}
/**
 * CxaSearchService is for cxa specific search endpoints. The search service is
 * seperate because the system needs to be cleanly isolated from the pos.
 *
 * @generated from protobuf service rd.search.service.CxaSearchService
 */
export interface ICxaSearchServiceClient {
    /**
     * @generated from protobuf rpc: CxaSearchSales(rd.search.service.CxaSearchSalesReq) returns (rd.search.service.CxaSearchSalesRes);
     */
    cxaSearchSales(input: CxaSearchSalesReq, options?: RpcOptions): UnaryCall<CxaSearchSalesReq, CxaSearchSalesRes>;
    /**
     * @generated from protobuf rpc: CxaSearchOutstandingSales(rd.search.service.CxaSearchOutstandingSalesReq) returns (rd.search.service.CxaSearchOutstandingSalesRes);
     */
    cxaSearchOutstandingSales(input: CxaSearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<CxaSearchOutstandingSalesReq, CxaSearchOutstandingSalesRes>;
    /**
     * @generated from protobuf rpc: CxaSearchReturns(rd.search.service.CxaSearchReturnsReq) returns (rd.search.service.CxaSearchReturnsRes);
     */
    cxaSearchReturns(input: CxaSearchReturnsReq, options?: RpcOptions): UnaryCall<CxaSearchReturnsReq, CxaSearchReturnsRes>;
}
/**
 * CxaSearchService is for cxa specific search endpoints. The search service is
 * seperate because the system needs to be cleanly isolated from the pos.
 *
 * @generated from protobuf service rd.search.service.CxaSearchService
 */
export class CxaSearchServiceClient implements ICxaSearchServiceClient, ServiceInfo {
    typeName = CxaSearchService.typeName;
    methods = CxaSearchService.methods;
    options = CxaSearchService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CxaSearchSales(rd.search.service.CxaSearchSalesReq) returns (rd.search.service.CxaSearchSalesRes);
     */
    cxaSearchSales(input: CxaSearchSalesReq, options?: RpcOptions): UnaryCall<CxaSearchSalesReq, CxaSearchSalesRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CxaSearchSalesReq, CxaSearchSalesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CxaSearchOutstandingSales(rd.search.service.CxaSearchOutstandingSalesReq) returns (rd.search.service.CxaSearchOutstandingSalesRes);
     */
    cxaSearchOutstandingSales(input: CxaSearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<CxaSearchOutstandingSalesReq, CxaSearchOutstandingSalesRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CxaSearchOutstandingSalesReq, CxaSearchOutstandingSalesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CxaSearchReturns(rd.search.service.CxaSearchReturnsReq) returns (rd.search.service.CxaSearchReturnsRes);
     */
    cxaSearchReturns(input: CxaSearchReturnsReq, options?: RpcOptions): UnaryCall<CxaSearchReturnsReq, CxaSearchReturnsRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CxaSearchReturnsReq, CxaSearchReturnsRes>("unary", this._transport, method, opt, input);
    }
}
