// @ts-strict-ignore
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/solid"
import { ComponentPropsWithoutRef, FunctionComponent } from "react"

import { classNames } from "lib/classNames"

type BannerMode = "error" | "success" | "warning" | "information" | "copy"

interface BannerProps extends ComponentPropsWithoutRef<"div"> {
  message: string
  mode: BannerMode
  onClose?: () => void
  onClickActionButton?: () => void
  actionButtonMessage?: string
  actionButtonMode?: "inline" | "block"
}

const getJustifyClassNames = (actionButtonMessage) =>
  actionButtonMessage ? "justify-start" : "justify-between"

export const Banner: FunctionComponent<BannerProps> = ({
  className,
  mode,
  message,
  onClickActionButton,
  actionButtonMessage,
  actionButtonMode,
  onClose,
  ...otherProps
}) => (
  <div
    data-testid={`${mode}-banner`}
    className={classNames(
      "rounded-md p-2",
      getContainerClassNames(mode),
      className
    )}
    {...otherProps}
  >
    <div
      className={classNames(
        "flex flex-row items-center",
        getJustifyClassNames(actionButtonMessage)
      )}
    >
      <div className="flex flex-row items-center gap-x-2">
        <div className="flex-shrink-0 self-start">
          <Icon mode={mode} />
        </div>
        <div className={`flex-1 text-sm ${mode} whitespace-pre-line`}>
          {message}
          {actionButtonMode === "inline" && (
            <span
              className="ml-1 cursor-pointer underline"
              onClick={onClickActionButton}
            >
              {actionButtonMessage}
            </span>
          )}
        </div>
      </div>
      {actionButtonMode === "block" && (
        <div
          className="ml-1 cursor-pointer underline"
          onClick={onClickActionButton}
        >
          {actionButtonMessage}
        </div>
      )}
      {!!onClose && (
        <button
          id="banner-button"
          data-testid="banner-close-button"
          type="button"
          className={classNames(
            "inline-flex focus:outline-none",
            getCloseButtonClassNames(mode)
          )}
          onClick={onClose}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      )}
    </div>
  </div>
)

function getContainerClassNames(mode: BannerMode): string {
  switch (mode) {
    case "error": {
      return "bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-100"
    }
    case "success": {
      return "bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-100"
    }
    case "warning": {
      return "bg-amber-100 text-amber-700 dark:bg-amber-900 dark:text-amber-100"
    }
    case "information": {
      return "bg-sky-100 text-sky-600 dark:bg-sky-900 dark:text-sky-100"
    }
    case "copy": {
      return "bg-white dark:bg-gray-800 text-primary"
    }
    default: {
      throw new Error("unexpected banner mode!")
    }
  }
}

function getCloseButtonClassNames(mode: BannerMode): string {
  switch (mode) {
    case "error": {
      return "text-red-700 dark:text-red-400"
    }
    case "success": {
      return "text-green-700 dark:text-green-400"
    }
    case "warning": {
      return "text-amber-700 dark:text-amber-400"
    }
    case "information": {
      return "text-sky-700 dark:text-sky-400"
    }
    case "copy": {
      return "text-primary"
    }
    default: {
      throw new Error("unexpected banner mode!")
    }
  }
}

const Icon: FunctionComponent<{ mode: BannerMode }> = ({ mode }) => {
  switch (mode) {
    case "error": {
      return (
        <ExclamationCircleIcon
          className="h-6 w-6 text-red-400"
          aria-hidden="true"
        />
      )
    }
    case "success": {
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      )
    }
    case "warning": {
      return (
        <ExclamationIcon
          className="h-6 w-6 text-amber-400"
          aria-hidden="true"
        />
      )
    }
    case "information": {
      return (
        <InformationCircleIcon
          className="h-6 w-6 text-sky-400"
          aria-hidden="true"
        />
      )
    }
    case "copy": {
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      )
    }
    default: {
      throw new Error("unexpected banner mode!")
    }
  }
}
